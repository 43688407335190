<template>
  <div class="shop-product-options">
    <ul>
      <li v-for="option in options" class="product-option" :key="option.id">
        <product-options-item :option="option" @updateVariant="updateVariant" />
      </li>
    </ul>
  </div>
</template>

<script>
import ProductOptionsItem from '@/components/shop/ProductOptionsItem'
export default {
  name: 'shopProductOptions',

  props: {
    options: {
      required: true,
      type: Array,
      default: () => {
        return []
      }
    }
  },

  components: {
    ProductOptionsItem
  },

  methods: {
    updateVariant(option, variant) {
      this.$emit('updateVariant', option, variant)
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'

.shop-product-options
  text-align: center

  ul
    padding: 0
    margin: 0
    list-style: none
</style>
