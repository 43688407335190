<template>
  <div class="page-shop">
    <transition name="fade" mode="out-in">
      <router-view
        :key="$route.fullPath"
        @openCart="openCart"
        @openSearch="openSearch"/>
    </transition>
  </div>
</template>

<script>
import page from '@/mixins/page'

export default {
  name: 'Shop',

  mixins: [page],

  methods: {
    openCart() {
      this.$emit('openCart')
    },

    openSearch() {
      this.$emit('openSearch')
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'

.products-list
  list-style: none
  padding: 0
  display: flex
  flex-wrap: wrap
  text-align: center
  padding: $gap-medium
  margin-top: 0

  @include themify($themes)
    background: themed('content-bg')

.shop-heading
  text-align: center
  margin-bottom: $gap-large

  @include media($media-lt-large)
    .has-large-logo &
      padding-top: $gap-x-large

  @include themify($themes)
    color: themed('text')

.is-searching
  .product-link
    display: none

    &.is-active
      display: block
</style>
