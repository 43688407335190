import {request} from '@/lib/request'

function sitePagePath(siteId, id) {
  return `/sites/${siteId}/pages/${id}`
}

/**
 * Get data for site page by id
 *
 * @param siteId {string} site uuid
 * @param id {string} page uuid
 *
 * @returns {promise}
 */
export function getSitePageData(siteId, id) {
  return request('get', sitePagePath(siteId, id))
}
