<template lang="html">
  <div class="responsive-img">
    <img :src="url" :alt="description" draggable="false" loading="lazy">
  </div>
</template>

<script>
  export default {
    name: 'LazyImage',
    introduction: 'Lazy load image at right size',
    description: '',
    token: '<responsive-img url="http://molly.nathansplace.co.uk/100x100.jpg" description="molly"></responsive-img>',

    props: {
      url: {
        default: '',
        note: 'The source of the image to be loaded in'
      },

      description: {
        type: String,
        default: '',
        note: 'Used in image alt tag'
      }
    }
  }
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/helpers/extends'

  .responsive-img
    display: inline-block

    >img
      max-width: 100%
      max-height: 100%
</style>
