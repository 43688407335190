<template>
  <div
    class="filter-inner types-filter"
    :class="{ 'show-clear': typeFilterActive }">
      <ul class="product-filter types">
        <!-- if products are loading
        <li key="loading" v-if="!firstPageLoaded">
          Loading...
        </li>
        -->
        <li
          v-for="type in types"
          :class="{ 'filter-active': type === appliedTypeFilter }"
          :key="type"
          @click="filter(type)">
          {{filterName(type)}}
        </li>
      </ul>
    <transition name="slide-left">
      <button v-if="typeFilterActive" class="clear-filter" @click="clear">✕</button>
    </transition>
  </div>

</template>

<script>
  export default {
    name: 'ProductTypeFilter',

    props: {
      types: Array,
      appliedTypeFilter: String,
      typeFilterActive: Boolean
    },

    computed: {
      filterName() {
        if(this.typeCount === 1) {
          return (type) => {
            return `Filter ${type}`
          }
        } else {
          return (type) => {
            return type
          }
        }
      },

      typeCount() {
        return this.types.length
      }
    },

    methods: {
      filter(type) {
        this.$emit('filter', type)
      },

      clear() {
        this.$emit('clear')
      }
    }
  }
</script>

