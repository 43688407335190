<template>
  <div v-if="hasNewsletter">
    <section class="newsletter">
      <h2>{{heading}}</h2>

      <div class="form">
        <NewsLetterForm 
          ref="NewsLetterForm" 
          @submit="signUp" 
          v-if="!signedUp" 
          v-model="newsletter"/>

        <div class="newsletter-success" v-if="signedUp">
          <p>You have successfully signed up!</p>
        </div>

        <div class="newsletter-error" v-if="serverError && !signedUp">
          <p v-html="serverError"></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import jsonp from '@/lib/jsonp'
  import NewsLetterForm from '@/components/forms/NewsLetterForm'

  export default {
    name: 'NewsLetter',

    data() {
      return {
        newsletter: {},
        serverError: false,
        signedUp: false
      }
    },

    components: {
      NewsLetterForm
    },

    computed: {
      ...mapGetters(['site']),

      newsletterData() {
        return {
          FNAME: this.newsletter.firstName,
          LNAME: this.newsletter.lastName,
          EMAIL: this.newsletter.email,
          SOURCE: 'Website',
          id: this.site.mailchimp.mailchimp_list_id,
          u: this.site.mailchimp.mailchimp_user_id
        }
      },

      hasNewsletter() {
        return !!this.site.mailchimp
      },

      heading() {
        return this.site.mailchimp.heading ? this.site.mailchimp.heading : 'Newsletter'
      }
    },

    methods: {
      signUp() {
        const form = this.$refs.NewsLetterForm

        form.$v.$touch()

        if(!form.$v.invalid) {
          jsonp.request({
            data: this.newsletterData,
            url: this.site.mailchimp.mailchimp_url,
            callbackName: 'c'
          }).then((args) => {
            let result = args.result

            if(result === 'error') {
              this.serverError = args.msg
              console.error(args)
            } else if(result === 'success') {
              this.signedUp = true
              this.resetNewsletter()
            } else {
              alert('An unknown error occured.')
            }
          })
        }
      },

      resetNewsletter() {
        const form = this.$refs.NewsLetterForm

        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.errors = {}
        form.$v.$reset()
      }
    }
  }
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/base/theme'

  .newsletter
    padding: 80px 0

    .newsletter-success, .newsletter-error
      text-align: center

    h2
      text-align: center
      display: block
      margin-bottom: $gap-large

      @include themify($themes)
        color: themed('text')

    .form
      max-width: 500px
      margin: auto
</style>
