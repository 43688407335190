<template>
  <div class="single-product">
    <div class="product-link">
      <router-link
        :to="{name: 'shop.product', params: {slug: product.handle}}">
        <LazyImage class="thumbnail" :url="productImage"/>

        <div class="product-info">
          <p>{{ product.title }}</p>
          <ProductPrice
            :price="productPrice"
            :compareAtPrice="compareAtPrice"/>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import ProductPrice from '@/components/shop/ProductPrice'
import LazyImage from '@/components/LazyImage'

export default {
  name: 'Product',

  props: {
    slug: {
      required: true,
      type: String
    }
  },

  components: {
    ProductPrice,
    LazyImage
  },

  computed: {
    ...mapState('shop', ['productsBySlug', 'shopifyClient']),

    product() {
      return this.productsBySlug[this.slug] || {}
    },

    productImage() {
      return this.product.images ? this.product.images[0].src : false
    },


    productPrice() {
      return this.product.variants ? this.product.variants[0].price : false
    },


    compareAtPrice() {
      return this.product.variants ? this.product.variants[0].compareAtPrice : false
    }
  },

  methods: {
    ...mapActions('shop', ['getProduct']),
  },

  mounted() {
    if (!this.productsBySlug[this.slug]) {
      let client = this.shopifyClient
      let slug = this.slug

      this.getProduct({client, slug})
    }
  }
}
</script>

<style lang="sass">
.single-product

</style>
