<template>
  <div class="social-share">
    <div v-for="(network, index) in socialNetworks" :key="`social-${index}`">
      <a
        :href="network.link"
        target="_blank"
        :title="`Share on ${network.name}`">

        <Sprite :class="`${network.icon}-icon`" :name="network.icon"/>

      </a>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'SocialShare',

  props: {
    url: {
      type: String,
      required: true
    }
  },

  components: {
    Sprite
  },

  data() {
    return {
      socialNetworks: [
        {
          name: 'Facebook',
          icon: 'facebook',
          link: `https://www.facebook.com/sharer.php?u=${this.url}`
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          link: `https://www.facebook.com/sharer.php?u=${this.url}`
        },
        {
          name: 'LinkedIn',
          icon: 'linkedin',
          link: `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}`
        },
        {
          name: 'Pinterest',
          icon: 'pinterest',
          link: `http://pinterest.com/pin/create/link/?url=${this.url}`
        },
        {
          name: 'Reddit',
          icon: 'reddit',
          link: `https://reddit.com/submit?url=${this.url}`
        },
        {
          name: 'Email',
          icon: 'email',
          link: `mailto:?subject=Check out this blog article!&body=${this.url}`
        }
      ]
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'
@import '../stylesheets/helpers/extends'

.social-share
  margin: $gap-medium 0
  display: flex

  .sprite
    width: 25px
    height: 25px

    &.reddit-icon
      fill: #EC5428

    &.email-icon
      fill: gray

  a
    margin-right: 12px
    display: block
    transition: transform .2s ease-in-out

    &:hover
      transform: scale(1.15)
</style>
