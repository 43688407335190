<template lang="html">
  <div class="product-price">
    <span>
      {{currencySymbol}}{{ price }}
    </span>

    <span
      v-if="compareAtPrice"
      class="compared-price">
      {{currencySymbol}}{{ compareAtPrice }}
    </span>
  </div>
</template>

<script>
  import currency from '@/mixins/currency'

  export default {
    name: 'ProductPrice',

    mixins: [currency],

    props: ['price', 'compareAtPrice']
  }
</script>

<style lang="sass">
.product-price
  font-weight: bold
  margin: 0

  .compared-price
    color: #a20707
    margin-left: 10px
    text-decoration: line-through
</style>
