/**
 * @returns {boolean} is app loading something from server
 */
export function loading(state) {
  return state.activeRequests > 0 && process.env.VUE_APP_TARGET === 'client'
}

/**
 * Current site pages
 */
export function pages(state) {
  return state.site.pages || []
}

/**
 * The site object
 * @returns Object
 */
export function site(state) {
  return state.site
}

/**
 * The navigation items
 */
export function navItems(state) {
  return state.site.menu_items
}

/**
 * The current page object
 */
export function currentPage(state, getters) {
  return state.pages[getters.pageId] || {}
}

/**
 * Id of current site
 */
export function siteId(state) {
  return state.site.id
}

export function siteName(state) {
  return state.site.name
}

export function titleBase(state) {
  return state.site.title_base
}

/**
 * Slug from route
 */
export function pageSlug(state) {
  let slug = state.route.path
    .split('')
    .slice(1)
    .join('')

  return slug === '' ? 'home' : slug
}

/**
 * Id of current page
 */
export function pageId(state, getters) {
  let page = getters.pages.find((p) => {
    return p.path === state.route.path
  })

  return typeof page !== 'undefined' ? page.id : null
}

export function themeSlug(state) {
  return state.site.theme.slug
}

/**
 * Site has a shop
 *
 * @returns boolean - whether or not there is a shop
 */
export function hasShop(state, getters) {
  let site = getters.site
  return site.addons.filter(a => a.name === 'shop').length > 0
}

/**
 * Site has a blog
 *
 * @returns boolean - whether or not there is a blog
 */
export function hasBlog(state, getters) {
  let site = getters.site
  return site.addons.filter(a => a.name === 'blog').length > 0
}