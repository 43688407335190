<template>
  <div class="product-images" v-if="this.images">
    <div class="image-wrapper">
      <div
        class="product-image"
        :style="`background-image:url('${selectedImageUrl}')`">

        <transition name="fade">
          <div v-if="isVideo(selectedImage)" class="product-video">
            <iframe :src="`https://youtube.com/embed/${videoId}?autoplay=1&loop=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0?playlist=${videoId}`" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
            </iframe>
          </div>
        </transition>
      </div>

      <div class="overflow-scroll" v-if="!oneImage">
        <transition-group name="fade" tag="ul" class="thumbnails" mode="in-out">
          <li
            v-for="(image, index) in images"
            class="image"
            :key="`product-image-${index}`"
            @click="selectImage(image)"
            :style="`background-image: url('${thumbnail(image)}')`">

            <div class="play-btn" v-if="isVideo(image)">
              <Sprite name="play"/>
            </div>
          </li>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Sprite from '@/components/Sprite'

  export default {
    name: 'ProductImages',

    props: {
      images: Array
    },

    components: {
      Sprite
    },

    data() {
      return {
        selectedImage: '',
        selectedImageUrl: ''
      }
    },

    mounted() {
      this.initProductImage()
    },

    computed: {
      ...mapState('shop', ['shopifyClient']),

      isVideo() {
        return (image) => {
          const hasAltText = !!image.altText
          const altText = image.altText
          return hasAltText && altText.includes('youtube')
        }
      },

      videoId() {
        if(this.isVideo(this.selectedImage)){
          return this.selectedImage.altText.split('v=')[1]
        }

        return false
      },

      oneImage() {
        return this.images.length <= 1
      },

      thumbnail() {
        return (image) => {
          return this.shopifyClient.image.helpers.imageForSize(image, {maxWidth: 300, maxHeight: 300})
        }
      }
    },

    methods: {
      initProductImage() {
        let selectedImg = this.images[0]

        this.selectedImage = selectedImg
        this.selectedImageUrl = this.shopifyClient.image.helpers.imageForSize(selectedImg, {maxWidth: 1200, maxHeight: 1200})
      },

      selectImage(image) {
        this.selectedImage = image
        this.selectedImageUrl = this.shopifyClient.image.helpers.imageForSize(image, {maxWidth: 1200, maxHeight: 1200})
      }
    },

    watch: {
      '$route'() {
        this.initProductImage()
      }
    }
  }
</script>

<style lang="sass">
  @import '../../stylesheets/base/variables'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/helpers/extends'

  .product-images
    width: 100%

    .thumbnails
      .image
        border-radius: 0

  .image-wrapper
    .spinner
      position: absolute
      top: 38%
      left: 48%
      z-index: -1

  .product-image
    width: 100%
    position: relative
    padding-bottom: 100%
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    height: 0

  .overflow-scroll
    @extend %overflow-scroll

  .thumbnails
    list-style: none
    padding: 0
    margin: 0
    display: flex
    flex-wrap: nowrap

    @include media($media-gt-small)
      flex-wrap: wrap
      width: 100%

    .image
      cursor: pointer
      background-size: 100% auto
      background-position: 50% 50%
      background-repeat: no-repeat
      height: 200px
      width: 200px
      position: relative
      transition: border .2s ease-in-out

      @include media($media-gt-small)
        height: auto

      @include media($media-gt-medium)
        flex-basis: percentage(1/3)

      &:before
        content: ''
        padding-bottom: 100%
        float: left

      @include media($media-gt-small)
        flex-basis: percentage(1/4)

  .product-video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    iframe
      width: 100%
      height: 100%

  .play-btn
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    fill: rgba(0, 0, 0, 0.5)
    width: 30px
    height: 30px
</style>
