import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Page from './views/Page'
import Policies from './views/Policies'
import Articles from './views/blog/Articles'
import Article from './views/blog/Article'

// TODO: Shop components in own chunk
import Shop from './views/shop/Shop'
import ShopCollections from './views/shop/Collections'
import ShopCollection from './views/shop/Collection'
import ShopProduct from './views/shop/Product'
import ShopProducts from './views/shop/Products'

Vue.use(Router)
Vue.use(VueMeta)

export function createRouter() {
  return new Router({
    mode: 'history',

    linkExactActiveClass: 'is-active',

    scrollBehavior() {
      return {x: 0, y: 0}
    },

    base: process.env.BASE_URL,

    routes: [
      {
        path: '/shop',
        component: Shop,

        children: [
          {
            path: '',
            name: 'shop.products',
            component: ShopProducts,
            meta: {
              type: 'product'
            }
          },

          {
            path: 'collections',
            name: 'shop.collections',
            component: ShopCollections
          },

          {
            path: 'collections/:slug',
            name: 'shop.collection',
            component: ShopCollection,
            props: true,
            meta: {
              type: 'collection'
            }
          },

          {
            path: 'products/:slug',
            name: 'shop.product',
            component: ShopProduct,
            props: true
          },

          {
            path: 'shop-privacy',
            name: 'privacy-policy',
            component: Policies,
            meta: {
              slug: 'privacyPolicy'
            }
          },

          {
            path: 'shop-terms',
            name: 'terms-policy',
            component: Policies,
            meta: {
              slug: 'termsOfService'
            }
          },

          {
            path: 'refund-policy',
            name: 'refund-policy',
            component: Policies,
            meta: {
              slug: 'refundPolicy'
            }
          }
        ]
      },

      {
        path: '/blog',
        name: 'blog',
        component: Articles
      },

      {
        path: '/blog/:slug',
        name: 'blog.article',
        props: true,
        component: Article
      },

      {
        path: '*',
        name: 'page',
        component: Page
      }
    ]
  })
}
