<template lang="html">
  <ul class="collections-list" v-if="collections">
    <li
      v-for="collection in collections"
      class="collection-link"
      :key="collection.id">

      <router-link
        :to="{name: 'shop.collection', params: {slug: collection.handle}}">
        <LazyImage v-if="hasImage(collection)" class="thumbnail" :url="collection.image.src"/>
        <p :class="{'has-image': hasImage(collection)}">{{ collection.title }}</p>
      </router-link>
    </li>
  </ul>
</template>

<script>
  import LazyImage from '@/components/LazyImage'

  export default {
    name: 'CollectionsList',

    props: {
      collections: Array
    },

    components: {
      LazyImage
    },

    computed: {
      hasImage() {
        return (collection) => {
          return !!collection.image
        }
      }
    }
  }
</script>

<style lang="sass">
  .collections-list
    list-style: none
    padding: 0
    margin: 0

  .collection-link
    a
      // padding: 0!important
      // display: inline-block!important
</style>
