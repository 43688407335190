import {createApp} from './app'

const {app, router, store} = createApp({})

// required to allow for async components
router.onReady(() => {
  // Load in initial vuex state
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }

  // this assumes App.vue template root element has `id="app"`
  app.$mount('#app')
})
