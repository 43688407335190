<template lang="html">
  <section class="page shop-policies">
    <div class="page-section" v-if="policy">
      <div class="container">
        <div v-html="this.shopPolicies[slug].body"></div>
      </div>
    </div>

    <div class="page-section footer-top">
      <FooterTop :hasShop="true"/>
    </div>

    <div class="page-section footer-bottom">
      <FooterBottom/>
    </div>
  </section>
</template>

<script>
  import {mapState} from 'vuex'
  import FooterTop from '@/components/FooterTop'
  import FooterBottom from '@/components/FooterBottom'

  export default {
    name: 'Policies',

    components: {
      FooterTop,
      FooterBottom
    },

    computed: {
      ...mapState('shop', ['shopPolicies']),

      policy() {
        return this.shopPolicies ? this.shopPolicies[this.slug] : false
      },

      slug() {
        return this.$route.meta.slug
      }
    }
  }
</script>
