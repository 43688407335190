<template>
  <section :class="{ 'is-active': cartOpen }" class="cart-panel">
    <header class="cart-header">
      <h2>Your cart</h2>
      <button class="close" @click="closeCart">&#10005;</button>
    </header>

    <section class="cart-items">
      <p class="centered" v-if="noItems">Your cart is empty.</p>

      <div v-if="lineItems">
        <div
          class="line-item"
          v-for="(lineItem, index) in lineItems"
          :key="`${lineItem.title}-${index}`">
          <div
            class="cart-img"
            v-if="lineItem.variant.image"
            :style="`background-image: url('${cartImage(lineItem.variant.image)}')`">
          </div>

          <div class="item-info">
            <p class="item-title">
              <span>{{lineItem.title}}</span>
            </p>

            <div class="remove-item" @click="onRemoveItem(lineItem.id)">
              <sprite name="bin"></sprite>
            </div>

            <ul class="item-variants" v-if="!noVariants(lineItem)">
              <li
                v-for="(option, index) in lineItem.variant.selectedOptions"
                :key="`option-${index}`">
                <p>{{option.name}}: <strong>{{option.value}}</strong></p>
              </li>
            </ul>
            <p>Price: <strong>{{currencySymbol}}{{lineItem.variant.price}}</strong> </p>
            <p>Quantity: <strong>{{lineItem.quantity}}</strong></p>
          </div>
        </div>
      </div>
    </section>

    <footer class="cart-footer">
      <div v-if="!noItems">
        <h3>Subtotal: {{currencySymbol}}{{subtotal}}</h3>
        <a class="btn" :href="shopCheckoutUrl">Check out</a>
      </div>
    </footer>
  </section>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import Sprite from '@/components/Sprite'
  import currency from '@/mixins/currency'

  export default {
    name: 'Cart',

    mixins: [currency],
    components: {Sprite},

    data() {
      return {
        content: {}
      }
    },

    props: {
      cartOpen: Boolean
    },

    computed: {
    ...mapState('shop', ['shopifyClient', 'checkout', 'checkoutId']),

      subtotal() {
        let amount = 0

        if(!this.noItems) {
          this.lineItems.forEach((item) => {
            let itemPrice = item.variant.price * item.quantity
            amount += itemPrice
          })

          return amount.toFixed(2)
        } else {
          return false
        }
      },

      shopCheckoutUrl() {
        return this.checkout.webUrl
      },

      noItems() {
        return this.lineItems ? this.lineItems.length === 0 : false
      },

      noVariants() {
        const title = 'Default Title'

        return function(lineItem) {
          return lineItem.variant.title === title
        }
      },

      lineItems() {
        return this.checkout.lineItems
      },

      cartImage() {
        return function(image) {
          return this.shopifyClient.image.helpers.imageForSize(image, {maxWidth: 300, maxHeight: 300})
        }
      }
    },

    methods: {
      ...mapActions('shop', ['removeFromCart']),

      onRemoveItem(id) {
        const client = this.shopifyClient
        const checkout = this.checkout
        const lineItems = [id]

        this.removeFromCart({client, checkout, lineItems})
      },

      closeCart() {
        this.$emit('toggleCart')
      }
    }
  }
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'

.cart-panel
  background: #191919
  color: #fff
  fill: #fff
  position: fixed
  display: flex
  flex-direction: column
  top: 0
  right: 0
  padding: $gap-medium
  width: 100%
  height: 100vh
  transform: translateX(calc(100% + 10px))
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25)
  transition: transform .3s ease-in-out
  z-index: 1000

  .cart-header
    h2
      margin: 0
      color: #FFF

  .cart-footer
    h3, .btn
      color: #FFF

  @include media($media-gt-small)
    width: 450px

  &.is-active
    transform: translateX(0)

  .close
    background: none
    font-size: 24px
    padding: 0

  .line-item
    display: flex
    margin: $gap-medium 0
    padding-bottom: 20px
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)

    &:last-child
      border-bottom: none
      margin-bottom: 0

  p
    color: #fff!important

  .cart-footer
    padding: $gap-small
    border-top: 1px solid rgba(255, 255, 255, 0.15)

    h3
      margin: 0 0 15px

    .btn
      display: block
      text-align: center
      padding: 12px
      background: $color-btn-checkout
      font-weight: bold
      animation: pulse 1s infinite ease-in-out

    @keyframes pulse
      0%
        transform: scale(1)
      50%
        transform: scale(1.025)
      100%
        transform: scale(1)

.cart-header
  display: flex
  justify-content: space-between
  margin: 0 0 15px

.cart-items
  overflow: scroll
  -webkit-overflow-scrolling: touch
  border-top: 1px solid rgba(255, 255, 255, 0.15)

.cart-img
  background-size: cover
  background-position: 50% 50%
  height: 75px
  margin: 5px
  flex-basis: 20%

.item-info
  padding: 0 30px 0 10px
  flex-basis: 80%
  position: relative

  p
    margin: 8px 0
    line-height: 1.2
    font-size: 14px

    &.item-title
      margin: 0 0 15px
      font-weight: bold
      line-height: 1.2
      font-size: 16px

.item-variants
  padding: 0
  margin: 0
  list-style: none

.remove-item
  position: absolute
  top: 0
  right: 0
  cursor: pointer
  opacity: 0.5

  .sprite
    width: 20px
    height: 20px
</style>

