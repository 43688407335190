/**
 * ErrorMessages - generate error messages for vuejs modules using vuelidate
 *
 * Generate error message computeds to show inline error messages to work with
 * vuelidate validations, and also with externally generated error messages
 * (normally serverside errors).
 *
 * Expects to find externally generated errors in `this.errors`... an object
 * structured like so:
 *
 * ```javascript
 *   {
 *     FIELD_NAME: ['error 1', 'error n', ...]
 *   }
 * ```
 *
 * ## Examples
 *
 * ```javascript
 *   import {errorMessages} from '@/api/error_messages'
 *
 *   // ... SNIP
 *
 *   computed: {
 *     ...errorMessages([FIELD_NAME, ...])
 *   }
 *
 *   // ... SNIP
 *
 * ```
 */

/**
 * Error messages come from here
 * TODO: fill in messages for all validations used
 */
const ERROR_MESSAGES = {
  email: 'Is not an email address',
  required: 'Is required',
  sameAs: 'Does not match',
  minLength: 'Is too short',
  maxLength: 'Is too long'
}

/**
 * errorMessages
 * @param {Array} fields - Array of strings, naming the fields for which you would like to generate error messages
 * @returns {Object} - Object containing functions to use as computeds in your vue module
 */
export function errorMessages(fields) {
  const out = {}

  fields.map((field) => {
    out[`${field}Error`] = function() {
      // Has an external error been registered?
      if (this.errors[field]) {
        return this.errors[field][0]
      }

      // Browser side validation
      if (this.$v.resource[field].$dirty) {
        const fail = Object.keys(this.$v.resource[field].$params).find(
          (validation) => {
            return !this.$v.resource[field][validation]
          }
        )

        if (fail) {
          return ERROR_MESSAGES[fail]
        }
      }
    }
  })

  return out
}
