import Client from 'shopify-buy'

/**
 * Shopify client interactions. Works client side only.
 */

/**
 * Initialise a new shopify client
 *
 * @param {string} domain - shopify store domain
 * @param {string} storefrontAccessToken - shopify token
 *
 * @returns {object} - shopify client
 */
export function createShopifyClient(domain, storefrontAccessToken) {
  return Client.buildClient({
    domain,
    storefrontAccessToken
  })
}

/**
 * Get shopify shop
 *
 * @param {object} client - shopify client
 *
 * @returns {promise}
 */
export function getShopPolicies(client) {
  return client.shop.fetchPolicies().then((policies) => {
    return policies
  })
}

/**
 * Get shopify products
 *
 * @param {object} client - shopify client
 * @param {string} id - shopify product id
 *
 * @returns {promise}
 */
export function getProducts(client) {
  return client.product.fetchAll()
}

/**
 * Get shopify product
 *
 * @param {object} client - shopify client
 * @param {string} id - shopify product id
 *
 * @returns {promise}
 */
export function getProduct(client, slug) {
  return client.product.fetchByHandle(slug)
}

/**
 * Get shopify collections
 *
 * @param {object} client - shopify client
 *
 * @returns {promise}
 */
export function getCollections(client) {
  return client.collection.fetchAll()
}

/**
 * Get shopify collection with its products
 *
 * @param {object} client - shopify client
 * @param {string} id - shopify collection id
 *
 * @returns {promise}
 */
export function getCollection(client, slug) {
  return client.collection.fetchByHandle(slug)
}

/**
 * Get shopify collection products
 *
 * @param {object} client - shopify client
 * @param {string} id - shopify collection id
 *
 * @returns {promise}
 */
export function getCollectionProducts(client, id) {
  return client.collection.fetchWithProducts(id).then((collection) => {
    return collection.products
  })
}

/**
 * Create a shopify checkout instance
 *
 * @param {object} client - shopify client
 *
 * @returns {promise}
 */
export function createCheckout(client) {
  return client.checkout.create()
}

export function getCheckout(client, id) {
  return client.checkout.fetch(id)
}

export function addToCart(client, checkoutId, lineItems) {
  return client.checkout.addLineItems(checkoutId, lineItems)
}

export function removeFromCart(client, checkoutId, lineItems) {
  return client.checkout.removeLineItems(checkoutId, lineItems)
}
