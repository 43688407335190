<template>
  <div class="page page-section page-shop-collection">
    <div class="container">
      <div class="shop-heading" id="filter-anchor">
        <h2>{{ collection.title }}</h2>
        <p v-html="collection.descriptionHtml"></p>
      </div>

      <ProductsList
        v-if="products.length"
        @openCart="openCart"
        :collectionSlug="slug"
        :listedAt="listedCollectionsAt"
        :products="products"
      />
    </div>

    <SectionSeparator v-if="separator" :separator="separator"/>
  </div>
</template>

<script>
import paginate from '@/mixins/paginate'

import ProductsList from '@/components/shop/ProductsList'
import SectionSeparator from '@/components/lists/SectionSeparator'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'shopCollectionList',

  mixins: [paginate],

  props: {
    items: {
      type: Array,
      required: true
    },
    separator: Object
  },

  data() {
    return {
      allProductsLoaded: false
    }
  },

  components: {
    ProductsList,
    SectionSeparator
  },

  computed: {
    ...mapState('shop', [
      'collectionsWithProductsBySlug',
      'shopifyClient',
      'productsList',
      'listedCollectionsAt',
      'collectionsProducts'
    ]),

    collections() {
      return this.items.map((item) => {
        return JSON.parse(item.value)
      })
    },

    slug() {
      return this.collections[0].collection_slug
    },

    collection() {
      return this.collectionsWithProductsBySlug[this.slug] || {}
    },

    title() {
      return this.collection.title
    },

    productIds() {
      return this.collectionsProducts
        .filter((cp) => {
          return (cp.collectionId = this.collection.id)
        })
        .map((cp) => {
          return cp.productId
        })
    },

    products() {
      return this.collection.products ? this.collection.products : []
    },

    shouldGetCollection() {
      return !Object.keys(this.collection).length || !this.collection.products
    }
  },

  methods: {
    ...mapActions('shop', ['getCollection']),

    fetchNextPage() {
      let client = this.shopifyClient

      client.fetchNextPage(this.products).then((nextProducts) => {
        let hasNext = nextProducts.model.length > 0

        if (!hasNext) {
          this.allProductsLoaded = true
        } else {
          nextProducts.model.forEach((product) => {
            this.products.push(product)
          })
        }
      })
    },

    openCart() {
      this.$emit('openCart')
    },

    fetchCollection() {
      if (this.shouldGetCollection) {
        let client = this.shopifyClient
        let slug = this.slug

        this.getCollection({client, slug})
        this.paginate()
      }
    }
  },

  mounted() {
    this.fetchCollection()
  },

  watch: {
    $route() {
      this.fetchCollection()
    }
  }
}
</script>

<style lang="sass">
.page-shop-collection
</style>
