<template>
  <svg
    version="1.1"
    id="Layer_1"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 162"
    style="enable-background:new 0 0 595.3 162;"
    xml:space="preserve"
  >
    <path
      class="bottom-svg"
      d="M0,162.3h595.3v-3c-29.5-17.1-63.2-30.7-100.4-37.9c-103.8-19.8-127.9,36.8-235.4,21.3
  C139.4,125.5,127,16.2,6.9,1.1C4.7,0.8,2.3,0.6,0,0.4L0,162.3L0,162.3z"
    />
    <path
      class="top-svg"
      d="M0,0.4c2.3,0.2,4.7,0.4,6.9,0.7c120,15.1,132.5,124.4,252.6,141.6c107.5,15.5,131.6-41.1,235.4-21.3
  c37.2,7.2,70.9,20.8,100.4,37.9V0.4H0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'waves-seperator'
}
</script>
