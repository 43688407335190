<template>
  <div class="page page-shop-collection">
    <div class="page-section">
      <div class="container">
        <Parallax>
          <div class="shop-heading" id="filter-anchor">
            <h1>{{ collection.title }}</h1>
            <p v-html="collection.descriptionHtml"></p>
          </div>
        </Parallax>

        <ProductsList
          v-if="products.length"
          @openCart="openCart"
          :collectionSlug="slug"
          :listedAt="listedCollectionsAt"
          :products="products"
          :allProductsLoaded="allProductsLoaded"
        />
      </div>

      <div v-if="!allProductsLoaded">
        <Spinner />
      </div>
    </div>

    <NewsLetter class="page-section" />
    <FooterTop class="page-section footer-top" />
    <FooterBottom class="page-section footer-bottom" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Parallax from '@/components/Parallax'
import ProductsList from '@/components/shop/ProductsList'
import Spinner from '@/components/Spinner'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'shopCollection',

  mixins: [page],

  props: {
    slug: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pageSlug: 'shop-collection',
      allProductsLoaded: false
    }
  },

  components: {
    ProductsList,
    Parallax,
    Spinner,
    NewsLetter,
    FooterTop,
    FooterBottom
  },

  computed: {
    ...mapState('shop', [
      'collectionsWithProductsBySlug',
      'shopifyClient',
      'productsList',
      'listedCollectionsAt',
      'collectionsProducts'
    ]),

    collection() {
      return this.collectionsWithProductsBySlug[this.slug] || {}
    },

    title() {
      return this.collection.title
    },

    productIds() {
      return this.collectionsProducts
        .filter((cp) => {
          return (cp.collectionId = this.collection.id)
        })
        .map((cp) => {
          return cp.productId
        })
    },

    products() {
      return this.collection.products ? this.collection.products : []
    },

    shouldGetCollection() {
      return !Object.keys(this.collection).length || !this.collection.products
    }
  },

  methods: {
    ...mapActions('shop', ['getCollection']),

    fetchNextPage() {
      let client = this.shopifyClient

      client.fetchNextPage(this.products).then((nextProducts) => {
        let hasNext = nextProducts.model.length > 0

        if (!hasNext) {
          this.allProductsLoaded = true
        } else {
          nextProducts.model.forEach((product) => {
            this.products.push(product)
          })
        }
      })
    },

    paginate() {
      let timer

      timer = window.setInterval(() => {
        if (!this.allProductsLoaded) {
          this.fetchNextPage()
        }
      }, 1500)

      if (this.allProductsLoaded) {
        clearInterval(timer)
      }
    },

    openCart() {
      this.$emit('openCart')
    },

    fetchCollection() {
      // if (this.shouldGetCollection) {
        let client = this.shopifyClient
        let slug = this.slug

        this.getCollection({client, slug})
        this.paginate()
      // }
    }
  },

  mounted() {
    this.fetchCollection()
  },

  watch: {
    '$route'() {
      console.log('get collection')
      this.fetchCollection()
    }
  }
}
</script>

<style lang="sass">
.page-shop-collection
</style>
