<template>
  <div class="page page-shop-product">
    <div class="page-section">
      <product-detail :product="product" @openCart="openCart"/>
    </div>

    <div class="page-section" v-if="collectionHandle">
      <ShopCollection :items="collectionHandle"/>
    </div>

    <NewsLetter class="page-section"/>
    <FooterTop class="page-section footer-top"/>
    <FooterBottom class="page-section footer-bottom"/>
  </div>
</template>

<script>
import page from '@/mixins/page'
import {mapState, mapActions} from 'vuex'

import ShopCollection from '@/components/shop/CollectionList'
import ProductDetail from '@/components/shop/ProductDetail'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

export default {
  name: 'shopProduct',

  mixins: [page],

  props: {
    slug: {
      required: true,
      type: String
    }
  },

  components: {
    ShopCollection,
    ProductDetail,
    NewsLetter,
    FooterTop,
    FooterBottom
  },

  data() {
    return {
      pageSlug: 'shop/product'
    }
  },

  computed: {
    ...mapState('shop', ['productsBySlug', 'shopifyClient']),

    product() {
      return this.productsBySlug[this.slug] || {}
    },

    title() {
      return this.product.title
    },

    collectionHandle() {
      let params = this.$route.query.collection
      let test

      if (params) {
        return [
          {value: `{"collection_slug": "${params}"}`}
        ]
      } else {
        return false
      }
    }
  },

  methods: {
    ...mapActions('shop', ['getProduct']),

    openCart() {
      this.$emit('openCart')
    }
  },

  mounted() {
    if (!this.productsBySlug[this.slug]) {
      let client = this.shopifyClient
      let slug = this.slug

      this.getProduct({client, slug})
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/theme'

.page-shop-product
  .page-section.page-shop-collection
      background: none!important
      margin: 0!important

</style>
