<template>
  <div>
    <lazy-image
      v-if="hasImages"
      class="responsive-image"
      :description="description"
      :url="url" />
  </div>
</template>

<script>
import LazyImage from '@/components/LazyImage'

export default {
  name: 'responsive-image',

  props: ['imageVersions', 'description', 'width'],

  components: {
    LazyImage
  },

  data() {
    return {
      initialWidth: 0
    }
  },

  computed: {
    hasImages() {
      return this.imageVersions.length > 0
    },

    imageVersion() {
      return (
        this.imageVersions.find((version) => {
          return version.width_px === this.testWidth
        }) || {}
      )
    },

    url() {
      return this.imageVersion.url
    },

    testWidth() {
      return Math.max(this.initialWidth, this.width)
    }
  },

  created() {
    this.initialWidth = this.width
  }
}
</script>

<style lang="sass">
.responsive-image
</style>
