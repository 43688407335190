<template>
  <div class="input" :class="`field-type-${fieldType}`">
    <label v-if="!labelPlaceholder" :for="_uid">{{ label }}</label>

    <input
      v-if="!special"
      :placeholder="inputPlaceholder"
      :id="_uid"
      :type="type"
      v-model="resource" />

    <textarea rows="5" :placeholder="placeholder" v-if="type === 'textarea'" v-model="resource" :id="_uid">
    </textarea>

    <select v-if="type === 'select'" :id="_uid" v-model="resource">
      <option value="">Choose one</option>

      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value === resource">
        {{ option.key }}
      </option>
    </select>

    <error :error="error"></error>
  </div>
</template>

<script>
import Error from '@/components/Error'
import input from '@/mixins/input'

export default {
  name: 'Field',

  mixins: [input],

  props: {
    label: String,
    error: String,
    placeholder: String,
    labelPlaceholder: Boolean,

    type: {
      type: String,
      default: 'text'
    },

    options: {
      type: Array
    }
  },

  components: {
    Error
  },

  computed: {
    special() {
      return ['textarea', 'select'].includes(this.type)
    },

    fieldType() {
      return this.type
    },

    inputPlaceholder() {
      return this.labelPlaceholder ? this.label : this.placeholder
    }
  }
}
</script>

<style lang="sass">
  @import '../stylesheets/base/variables'
  @import '../stylesheets/helpers/mixins'
  @import '../stylesheets/base/theme'

  .input
    padding: 10px

    &.field-type-checkbox
      display: flex
      align-items: center
      margin-top: $gap-small

      label
        margin: 0 10px 0 0

    label
      @include themify($themes)
        color: themed('text')
        font-size: 22px
        opacity: 0.5

    input
      &[type='checkbox']
        width: 20px
        height: 20px

    input, textarea, select
      font-family: $font-body
      padding: 10px
      font-size: 22px
      background: none
      border: none
      width: 100%
      transition: border-bottom .2s ease-in-out
      border-bottom: 1px solid

      @include themify($themes)
        border-color: themed('border-color')
        color: themed('text')

      &:focus
        @include themify($themes)
          border-color: themed('border-color-active')

      @include placeholder
        @include themify($themes)
          color: themed('text')
          opacity: 0.5
</style>
