export const LOADING = 'LOADING'
export const SET_SITE = 'SET_SITE'
export const ADD_PAGE = 'ADD_PAGE'

export const REQUEST_SITE_ARTICLE = 'REQUEST_SITE_ARTICLE'
export const REQUEST_SITE_ARTICLES = 'REQUEST_SITE_ARTICLES'
export const RECEIVE_SITE_ARTICLES = 'RECEIVE_SITE_ARTICLES'
export const RECEIVE_SITE_ARTICLE = 'RECEIVE_SITE_ARTICLE'
export const REQUESTED_SITE_ARTICLE = 'REQUESTED_SITE_ARTICLE'
export const REQUESTED_SITE_ARTICLES = 'REQUESTED_SITE_ARTICLES'
