/**
 * Shop mutations
 */
export const LOADING = 'LOADING'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CURRENCY = 'SET_CURRENCY'
export const ADD_COLLECTION = 'ADD_COLLECTION'
export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const ADD_PRODUCT = 'ADD_PRODUCT'

export const REQUEST_SHOP_POLICIES = 'REQUEST_SHOP_POLICIES'
export const RECEIVE_SHOP_POLICIES = 'RECEIVE_SHOP_POLICIES'

export const REQUEST_CURRENCY = 'REQUEST_CURRENCY'
export const RECEIVE_CURRENCY = 'RECEIVE_CURRENCY'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'

export const REQUEST_PRODUCT = 'REQUEST_PRODUCT'
export const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT'

export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS'
export const RECEIVE_COLLECTIONS = 'RECEIVE_COLLECTIONS'

export const REQUEST_COLLECTION = 'REQUEST_COLLECTION'
export const RECEIVE_COLLECTION = 'RECEIVE_COLLECTION'

export const REQUEST_COLLECTION_PRODUCTS = 'REQUEST_COLLECTION_PRODUCTS'
export const RECEIVE_COLLECTION_PRODUCTS = 'RECEIVE_COLLECTION_PRODUCTS'

export const REQUEST_CHECKOUT = 'REQUEST_CHECKOUT'
export const RECEIVE_CHECKOUT = 'RECEIVE_CHECKOUT'
