import Vue from 'vue'

import * as types from './types'

function loading(state) {
  Vue.set(state, 'activeRequests', state.activeRequests + 1)
}

function loaded(state) {
  Vue.set(state, 'activeRequests', state.activeRequests - 1)
}

export default {
  /**
   * Count active ajax requests
   *
   * @param state {object} current state
   * @param value {boolean} true for opening connection, false for closing
   */
  [types.LOADING](state, value) {
    let activeRequests = state.activeRequests
    let count = value ? activeRequests + 1 : activeRequests - 1

    Vue.set(state, 'activeRequests', count)
  },

  [types.REQUEST_SHOP_POLICIES](state) {
    loading(state)
  },

  [types.RECEIVE_SHOP_POLICIES](state, {shopPolicies, getAt}) {
    loaded(state)
    Vue.set(state, 'shopPolicies', shopPolicies)
    Vue.set(state, 'listedShopPoliciesAt', getAt)
  },

  [types.REQUEST_COLLECTIONS](state, getAt) {
    Vue.set(state, 'listedCollectionsAt', getAt)
  },

  /**
   * Populate collections list, and collections by slug with new collections
   * Also set state.listedCollectionsAt
   *
   * @param state {object} current state
   * @param collections {Array} list of shopify collections
   */
  [types.RECEIVE_COLLECTIONS](state, {collections, getAt}) {
    loaded(state)

    Vue.set(state, 'listedCollectionsAt', getAt)
    Vue.set(state, 'collectionsList', collections)

    // Set collections by slug
    collections.forEach((collection) => {
      Vue.set(state.collectionsBySlug, collection.handle, collection)
    })
  },

  [types.REQUEST_COLLECTION](state) {
    loading(state)
  },

  [types.RECEIVE_COLLECTION](state, {collection}) {
    loaded(state)

    // If we havent listed this collection, add it to the list
    if (
      !state.collectionsList.find((c) => {
        return collection.id === c.id
      })
    ) {
      Vue.set(state, 'collectionsList', [collection, ...state.collectionsList])
    }

    // collection by slug
    Vue.set(state.collectionsBySlug, collection.handle, collection)
    Vue.set(state.collectionsWithProductsBySlug, collection.handle, collection)

    // find products for collection that are not already in `productsList`
    // let newProducts = collection.products.filter((product) => {
    //   return !state.productsList.find((p) => {
    //     return p.id === product.id
    //   })
    // })

    // Vue.set(state, 'productsList', [...newProducts, ...state.productsList])

    console.log(collection)

    collection.products.map((product) => {
      Vue.set(state.productsBySlug, product.handle, product)
    })
  },

  /**
   * Store shopify client instance
   *
   * @param state {object} current state
   * @param client {Object} shopify client
   */
  [types.SET_CLIENT](state, client) {
    Vue.set(state, 'shopifyClient', client)
  },

  [types.REQUEST_PRODUCTS](state) {
    loading(state)
  },

  [types.RECEIVE_PRODUCTS](state, {products, getAt}) {
    loaded(state)

    Vue.set(state, 'listedProductsAt', getAt)
    Vue.set(state, 'productsList', products)

    products.map((product) => {
      Vue.set(state.productsBySlug, product.handle, product)
    })
  },

  [types.REQUEST_PRODUCT](state) {
    loading(state)
  },

  [types.RECEIVE_PRODUCT](state, {product}) {
    loaded(state)

    Vue.set(state, 'productsList', [product, ...state.productsList])
    Vue.set(state.productsBySlug, product.handle, product)
  },

  [types.REQUEST_CHECKOUT](state) {
    loading(state)
  },

  [types.RECEIVE_CHECKOUT](state, {checkout}) {
    loaded(state)

    Vue.set(state, 'checkout', checkout)
    Vue.set(state, 'checkoutId', checkout.id)
    Vue.set(state, 'currency', checkout.currencyCode)
  }
}
