import {request} from '@/lib/request'

function siteArticlesPath(siteId) {
  return `/sites/${siteId}/articles`
}

function siteArticlePath(siteId, slug) {
  return `${siteArticlesPath(siteId)}/${slug}`
}

export default {
  /**
   * Get paginated data for site articles
   *
   * @param siteId {string} site uuid
   * @param id {string} article uuid
   * @param page {object} pagination
   *
   * @returns {promise}
   */
  getSiteArticles({siteId, pagination}) {
    return request('get', siteArticlesPath(siteId), {
      params: {pagination}
    })
  },

  /**
   * Get data for site article by id
   *
   * @param siteId {string} site uuid
   * @param id {string} article uuid
   *
   * @returns {promise}
   */
  getSiteArticle({siteId, slug}) {
    return request('get', siteArticlePath(siteId, slug))
  }
}
