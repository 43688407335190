/**
 * Page mixin for view components
 */

import {mapGetters} from 'vuex'

export default {
  metaInfo() {
    let base = this.titleBase || this.siteName

    return {
      title: `${this.title} | ${base}`,
      meta: this.meta || []
    }
  },

  computed: {
    ...mapGetters(['siteName', 'titleBase'])
  }
}
