import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const store = {
  state: {
    activeRequests: 0,
    shopifyClient: null,
    collectionsList: [],
    productsList: [],
    collectionsBySlug: {},
    collectionsWithProductsBySlug: {},
    productsBySlug: {},
    listedShopAt: null,
    listedCollectionsAt: null,
    listedProductsAt: null,
    currency: null,
    collectionsProducts: [],
    checkout: {},
    shopPolicies: {},
    checkoutId: null
  },

  namespaced: true,

  mutations,
  actions,
  getters
}

export default store
