import Axios from 'axios'

/**
 * Prepend api host url to a path
 *
 * @param {string} url - a request path
 *
 * @returns {string}
 */
function prependHost(url) {
  let vueTarget = process.env.VUE_APP_TARGET
  let urlBase = process.env[`VUE_APP_API_HOST_${vueTarget.toUpperCase()}`]

  return `${urlBase}${url}`
}

/**
 * Bridges to axios request, and sets some request defaults
 *
 * @param {string} method - a request method
 * @param {string} url - a request path relative to root
 * @param {object} data - request body as object
 *
 * @returns {promise}
 */
export function request(method, url, data = {}) {
  return Axios.request({
    url: prependHost(url),
    method: method,
    data: data
  })
}
