<template lang="html">
  <div class="loader">
    <img src="../assets/loading.svg">
  </div>
</template>

<script>

  export default {
    name: 'loader'
  }
</script>

<style lang="sass">
  .loader
    margin: auto
    width: 60px

    img
      width: 60px
      background: rgba(0, 0, 0, 0.15)
      border-radius: 50%
      padding: 10px
</style>
