export default {
  props: ['value', 'json'],

  computed: {
    resource: {
      get() {
        return this.json ? JSON.stringify(this.value) : this.value
      },

      set(newValue) {
        let out = this.json ? JSON.parse(newValue) : newValue

        this.$emit('input', out)
      }
    }
  }
}
