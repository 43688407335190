import { render, staticRenderFns } from "./Collections.vue?vue&type=template&id=c9b3535c&"
import script from "./Collections.vue?vue&type=script&lang=js&"
export * from "./Collections.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Collections.vue?vue&type=style&index=0&lang=sass&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "9f2b146e"
  
)

export default component.exports