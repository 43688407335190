<template>
  <a
    class="social-link"
    target="_blank"
    :href="platformUrl"
    rel="noopener"
  >
    <span>{{platformKey}}</span>
    <Sprite :name="platformKey" />
  </a>
</template>

<script>
import Sprite from '@/components/Sprite'

const PLATFORMS = {
  LINKEDIN: 'linkedin',
  SNAPCHAT: 'snapchat',
  GOOGLE: 'google'
}

export default {
  name: 'SocialLink',

  props: {
    platform: {
      type: Object,
      required: true
    }
  },

  components: {
    Sprite
  },

  computed: {
    platformKey() {
      return this.platform.key
    },

    platformValue() {
      return this.platform.value
    },

    platformUrl() {
      const key = this.platformKey

      if (key === PLATFORMS.GOOGLE) {
        return this.platformValue
      }

      if (key === PLATFORMS.LINKEDIN) {
        return this.linkedinUrl
      }

      if (key === PLATFORMS.SNAPCHAT) {
        return this.snapchatUrl
      }

      return this.genericUrl
    },

    linkedinUrl() {
      return `https://${this.platformKey}.com/in/${this.platformValue}`
    },

    snapchatUrl() {
      return `https://${this.platformKey}.com/add/${this.platformValue}`
    },

    genericUrl() {
      return `https://${this.platformKey}.com/${this.platformValue}`
    }
  }
}
</script>

<style lang="sass">
.social-link
  span
    visibility: hidden
    position: absolute
</style>
