export default {
  methods: {
    paginate() {
      let timer

      timer = window.setInterval(() => {
        if (this.products.length) {
          this.fetchNextPage()
        }
      }, 1500)

      if (this.allProductsLoaded) {
        clearInterval(timer)
      }
    }
  }
}
