<template>
  <div id="app" :class="siteClasses">
    <SiteBlocked v-if="!siteActive"/>

    <div v-else>
      <Navigation
        :searchActive="searchOpen"
        @navActive="navActive"
        @toggleCart="toggleCart"
        @toggleSearch="toggleSearch"/>

      <main @click.capture="closeOverlays">
        <transition name="fade" mode="out-in">
          <router-view
            :key="$route.path"
            :style="{background: siteBackground}"
            @toggleCart="toggleCart"
            @openCart="openCart"
            @openSearch="openSearch"
          />
        </transition>
      </main>

      <Cart v-if="checkoutId" :cartOpen="cartOpen" @toggleCart="toggleCart" />

      <ShopSearch v-if="searchOpen" @closeOverlay="closeSearch"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'

import {mapActions, mapGetters, mapState} from 'vuex'

import Navigation from '@/components/Navigation'
import Cart from '@/components/shop/Cart'
import ShopSearch from '@/components/shop/ShopSearch'
import SiteBlocked from '@/components/SiteBlocked'

export default {
  data() {
    return {
      preventScroll: false,
      cartOpen: false,
      searchOpen: false,
      previewMode: '',
    }
  },

  components: {
    Navigation,
    Cart,
    ShopSearch,
    SiteBlocked
  },

  computed: {
    ...mapGetters([
      'loading',
      'themeSlug',
      'currentPage',
      'siteId',
      'site',
      'pageSlug'
    ]),
    ...mapState(['hostName', 'site']),
    ...mapState('shop', ['shopifyClient', 'checkoutId', 'checkout']),

    shouldGetCheckout() {
      return !!this.checkoutId && !Object.keys(this.checkout).length
    },

    subscriptionCancelled() {
      return !this.site.subscription_active
    },

    siteActive() {
      if(this.site.white_label) {
        return this.site.active
      } else {
        if(this.site.subscription_active) {
          return this.site.active
        }

        if(this.site.subscription_trialing) {
          return this.site.active
        }
      }
    },

    siteClasses() {
      return [
        `theme-${this.themeSlug}`,
        {'prevent-scroll': this.preventScroll},
        {'cart-open': this.cartOpen},
        {'search-open': this.searchOpen},
        {'has-large-logo': this.largeLogo}
      ]
    },

    siteName() {
      return this.site.name
    },

    fontCss() {
      return (font) => {
        let fontName = font.font.family.replace(/\s+/g, '+')

        if (font.type === 'body') {
          return `
            @import url('https://fonts.googleapis.com/css2?family=${fontName}');

            body, p, span, a, .btn, td, h4, h5, h6 {
              font-family: ${font.font.family}
            }
          `
        } else {
          return `
            @import url('https://fonts.googleapis.com/css2?family=${fontName}');

            h1, h2, h3 {
              font-family: ${font.font.family}
            }
          `
        }
      }
    },

    siteBackground() {
      return this.site.background_color ? this.site.background_color : '#8e8e8e'
    },

    largeLogo() {
      return this.site.large_logo
    },

    styleSheetPath() {
      return `/api/v1/sites/${this.siteId}/style.css`
    },

    faviconPath() {
      return this.site.favicon_path || this.faviconLogo
    },

    faviconLogo() {
      if (this.site.logo_versions) {
        return this.site.logo_versions.find((logo) => {
          return logo.name === 'favicon'
        })
      } else {
        return false
      }
    },

    metaDescription() {
      return this.currentPage.description || this.site.default_description
    },

    pageTitle() {
      return this.currentPage.title || this.siteName
    },

    meta() {
      return [
        {
          vmid: 'description',
          name: 'description',
          property: 'description',
          content: this.metaDescription
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.pageTitle
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: `${this.hostName}/${this.pageSlug}`
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: this.currentPage.preview_image
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.metaDescription
        }
      ]
    }
  },

  metaInfo() {
    return {
      meta: this.meta || [],
      link: [
        {rel: 'stylesheet', href: this.styleSheetPath},
        {rel: 'shortcut icon', href: this.faviconPath}
      ]
    }
  },

  methods: {
    ...mapActions(['getSite']),
    ...mapActions('shop', [
      'getShopPolicies',
      'createShopifyClient',
      'createCheckout',
      'getCheckout'
    ]),

    navActive(value) {
      this.preventScroll = value
    },

    closeOverlays() {
      if(this.cartOpen) {
        this.closeCart()
      }

      if(this.searchOpen) {
        this.closeSearch()
      }
    },

    openSearch() {
      this.searchOpen = true
    },

    closeSearch() {
      this.searchOpen = false
    },

    toggleSearch() {
      this.searchOpen = !this.searchOpen
    },

    openCart() {
      this.cartOpen = true
    },

    closeCart() {
      this.cartOpen = false
    },

    toggleCart() {
      this.cartOpen = !this.cartOpen
    },

    handleGoogleFonts() {
      let fontCss
      let style
      let styleText
      let fonts = []
      let headingFont = this.site.font_heading
      let bodyFont = this.site.font_body

      if (headingFont) {
        fonts.push({
          type: 'heading',
          font: headingFont
        })
      }

      if (bodyFont) {
        fonts.push({
          type: 'body',
          font: bodyFont
        })
      }

      fonts.forEach((font) => {
        fontCss = this.fontCss(font)
        style = document.createElement('style', fontCss)
        styleText = document.createTextNode(fontCss)
        style.type = 'text/css'
        style.appendChild(styleText)

        document.head.appendChild(style)
      })
    },

    handleCustomScripts() {
      if(this.site.javascript) {
        const id = 'external-script'
        const externalScript = document.getElementById(id)
        const delay = 1000

        if(externalScript) {
          externalScript.text = ''
        }

        window.setTimeout(() => {
          const script = document.createElement('script')

          script.type = 'text/javascript'
          script.id = id
          script.text = this.site.javascript

          document.head.appendChild(script)
        }, delay)
      }
    },

    handleFacebookPixel() {
      if(this.site.facebook_pixel) {
        const pixelImage = document.getElementById('facebook-pixel')
        const content = this.pageSlug
        const delay = 1000

        if(pixelImage) {
          pixelImage.outerHTML = ""
        }

        window.setTimeout(() => {
          const imageEl = document.createElement('IMG')

          imageEl.id = 'facebook-pixel'
          imageEl.src = `https://www.facebook.com/tr?id=${this.site.facebook_pixel}&ev=PageView&cd[content_name]=${content} page`
          imageEl.style = 'height:1px; width:1px; display: none'

          document.body.append(imageEl)
        }, delay)
      }
    },

    initGoogleAnalytics() {
      const analyticsId = this.site.analytics_id
      const pmpAnalyticsId = process.env.VUE_APP_ANALYTICS_ID

      Vue.use(VueGtag, {
        config: { id: analyticsId },
        includes: [
          { id: pmpAnalyticsId }
        ]
      }, this.$router)
    },

    initGoogleTagManager() {
      const prod = process.env.NODE_ENV === 'production'

      Vue.use(VueGtm, {
        id: this.site.gtm_id,
        debug: !prod,
        loadScript: true,
        vueRouter: this.$router
      })
    }
  },

  beforeMount() {
    // Shop initialisation needed for all areas of the shop
    if (!this.shopifyClient) {
      let domain = this.site.shopify_url
      let storefrontAccessToken = this.site.shopify_token

      if (domain) {
        this.createShopifyClient({
          domain,
          storefrontAccessToken
        })
      }
    }
  },

  mounted() {
    let client = this.shopifyClient
    let domain = this.site.shopify_url

    this.handleGoogleFonts()
    this.handleFacebookPixel()
    this.handleCustomScripts()
    this.initGoogleAnalytics()
    this.initGoogleTagManager()

    if (domain) {
      if (this.shouldGetCheckout) {
        // FIXME: Persisted checkoutId is not being loaded back into state
        let checkoutId = this.checkoutId

        this.getCheckout({client, checkoutId})
        console.log('retrieved checkout: ' + checkoutId)
      } else {
        console.log('creating checkout')
        this.createCheckout({client})
      }

      this.getShopPolicies({client})
    }
  },

  serverPrefetch() {
    return this.getSite(this.hostName)
  },

  watch: {
    $route() {
      this.handleFacebookPixel()
      this.handleCustomScripts()
    }
  }
}
</script>

<style lang="sass">
@import 'stylesheets/base/variables'
@import 'stylesheets/base/typography'
@import 'stylesheets/base/animations'
@import 'stylesheets/base/theme'
@import 'stylesheets/helpers/mixins'
@import 'stylesheets/helpers/extends'
@import 'stylesheets/themes/all'

*
  box-sizing: border-box

html
  scroll-behavior: smooth

body
  margin: 0
  font-family: 'Open Sans', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-size: 14px
  background: #fff

#app
  display: flex
  flex-direction: column
  min-height: 100vh

  &:before
    content: ''
    opacity: 0
    pointer-events: none
    top: -$min-nav-height
    background: rgba(0, 0, 0, 0.5)
    transition: all .3s ease-in-out
    width: 100%
    height: calc(100% + #{$min-nav-height})
    position: fixed
    z-index: 600

  &.prevent-scroll
    height: 100vh
    overflow: hidden

  &.cart-open, &.search-open
    &:before
      opacity: 1

main
  flex: 1

  @include themify($themes)
    background: themed('background')

.fade-enter-active, .fade-leave-active
  transition: opacity .3s ease-in-out

.fade-enter, .fade-leave-to
  opacity: 0

.container
  margin: auto
  padding: $gap-small

  &.narrow
    max-width: $max-width-narrow

.two-columns
  @include media($media-gt-medium)
    display: flex
    flex-wrap: wrap

    .col
      flex-basis: percentage(1/2)
      padding: 0 $gap-medium

.three-columns
  @include media($media-gt-medium)
    display: flex
    flex-wrap: wrap

    .col
      flex-basis: percentage(1/3)
      padding: 0 $gap-medium

.btn, button
  cursor: pointer
  font-family: $font-body
  font-size: 12px
  border: none
  transition: all .3s ease-in-out

  @include themify($themes)
    background: themed('btn-background')
    color: themed('btn-text')

  &:focus
    outline: none

.mobile-only
  @extend %mobile-only

.desktop-only
  @extend %desktop-only

hr
  margin: $gap-x-large 0
  width: 100%
  height: 1px
  border: none

  @include themify($themes)
    background: themed('hr-color')

.content-bg
  box-shadow: $content-shadow

  @include themify($themes)
    background: themed('content-bg')
    padding: $gap-medium

  hr
    margin: $gap-large 0

.product-link
  flex-basis: percentage(1/2)
  align-items: center
  padding: 10px

  @include media($media-gt-medium)
    flex-basis: percentage(1/4)

  .responsive-img
    display: flex
    align-items: center
    justify-content: center

  a
    text-decoration: none

.animate-section

</style>
