<template lang="html">
  <div :class="{'is-searching': hasQuery}">
    <ul class="products-list">
      <li
        v-for="product in filteredProducts"
        class="product-link"
        :class="{'is-active': search(product)}"
        :key="`product-link-${product.id}`">

        <router-link
          :to="productLink(product)">
          <LazyImage class="thumbnail" :url="productImage(product)"/>

          <div class="product-actions">
            <div class="quick-add" @click.prevent="quickAdd($event, product)">
              <Sprite name="cart"/>
              <span>Add to cart</span>
            </div>

            <div class="product-contact">
              <router-link :to="{ path: '/contact', query: { product: product.title } }">
                <Sprite name="mail"/>
              </router-link>
            </div>
          </div>

          <p>{{ product.title }}</p>
          <ProductPrice
            :price="productPrice(product)"
            :compareAtPrice="compareAtPrice(product)"/>
        </router-link>
      </li>
    </ul>

    <p
      v-if="allProductsLoaded && noSearchResults"
      class="no-results">
      End of results.
    </p>

    <transition name="fade">
      <div v-if="variantOptionsActive" class="variant-options" @click.self="hideVariants">
        <div class="inner">
          <h1>{{activeProduct.title}}</h1>
          <h2>
            <ProductPrice
              :price="variantPrice"
              :compareAtPrice="variantComparePrice"/>
          </h2>

          <product-options
            :options="activeProduct.options"
            @updateVariant="updateVariant">
          </product-options>

          <ProductQuantity @updateQuantity="updateQuantity"/>

          <hr>

          <div class="add-to-cart">
            <button
              id="btn-atc"
              :class="{ 'is-disabled': !selectedVariantAvailable }"
              class="btn"
              @click="onAddToCart($event, selectedVariantId)">
              {{cartButtonText}}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  import LazyImage from '@/components/LazyImage'
  import ProductPrice from '@/components/shop/ProductPrice'
  import ProductOptions from '@/components/shop/ProductOptions'
  import ProductQuantity from '@/components/shop/ProductQuantity'
  import Sprite from '@/components/Sprite'

  export default {
    name: 'ProductLinks',

    data() {
      return {
        variantOptionsActive: false,
        quantity: 1,
        totalSelectedOptions: 0,
        activeProduct: {},
        selectedOptions: {},
        selectedVariantAvailable: '',
        selectedVariantId: '',
        variantPrice: '',
        variantComparePrice: ''
      }
    },

    props: {
      filteredProducts: Array,
      collectionSlug: String,
      sorting: Boolean,
      noProducts: Boolean,
      allProductsLoaded: Boolean,
      hasQuery: Boolean,
      filterQuery: String
    },

    components: {
      ProductPrice,
      ProductQuantity,
      ProductOptions,
      LazyImage,
      Sprite
    },

    computed: {
      ...mapState('shop', ['shopifyClient', 'checkout']),

      productLink() {
        const type = this.$route.meta.type

        return(product) => {
          if(type === 'collection') {
            return {
              name: 'shop.product',
              params: {
                slug: product.handle
              },
              query: {
                collection: this.collectionSlug
              }
            }
          } else {
            return {
              name: 'shop.product',
              params: {
                slug: product.handle
              }
            }
          }
        }
      },

      search() {
        return (product) => {
          let title = product.title.toLowerCase()

          if(this.hasQuery) {
            let query = this.filterQuery.toLowerCase()
            return title.includes(query)
          }
        }
      },

      noSearchResults() {
        if(this.hasQuery) {
          let activeProducts = document.querySelectorAll('.product-link.is-active')
          return activeProducts.length === 0
        } else {
          return false
        }
      },

      productImage() {
        return (product) => {
          let image = product.images[0]
          return this.shopifyClient.image.helpers.imageForSize(image, {maxWidth: 500, maxHeight: 500})
        }
      },

      productPrice() {
        return (product) => {
          return product.variants[0].price
        }
      },

      compareAtPrice() {
        return (product) => {
          return product.variants[0].compareAtPrice
        }
      },

      cartButtonText() {
        let selectText

        if(this.totalOptions > 1) {
          selectText = 'Select Options'
        } else {
          selectText = 'Select an option'
        }

        if(!this.allOptionsSelected) {
          return selectText
        } else if(!this.selectedVariantAvailable) {
          return 'Sold out'
        } else {
          return 'Add to cart'
        }
      },

      totalOptions() {
        if(this.activeProduct.options) {
          return this.activeProduct.options.length
        } else {
          return false
        }
      },

      allOptionsSelected() {
        if(this.activeProduct.options) {
          return this.totalSelectedOptions === this.activeProduct.options.length
        } else {
          return false
        }
      },
    },

    methods: {
      ...mapActions('shop', ['addToCart']),

      quickAdd(event, product) {
        /**
         * If product has variants, open overlay
         * Otherwise add product to cart
         */
        let noVariants = product.variants[0].title === 'Default Title'

        if(noVariants) {
          let variantId = product.variants[0].id
          this.onAddToCart(event, variantId)
        } else {
          this.showVariants(event, product)
        }
      },

      showVariants(event, product) {
        this.activeProduct = product
        this.setVariantPrice()
        this.variantOptionsActive = true
      },

      hideVariants() {
        this.variantOptionsActive = false
      },

      updateVariant(option, variant) {
        this.selectedOptions[option.name] = variant.value

        this.getOptionsLength()

        console.log(this.allOptionsSelected)

        if(this.allOptionsSelected) {
          this.getSelectedVariant()
        }
      },

      getOptionsLength() {
        let total = Object.keys(this.selectedOptions).length
        this.totalSelectedOptions = total
      },

      setVariantPrice(price, compareAtPrice) {
        if(price) {
          this.variantPrice = price
          this.variantComparePrice = compareAtPrice
        } else {
          this.variantPrice = this.activeProduct.variants[0].price
          this.variantComparePrice = this.activeProduct.variants[0].compareAtPrice
        }
      },

      getSelectedVariant() {
        let selectedVariant =
          this.shopifyClient.product.helpers.variantForOptions(
            this.activeProduct,
            this.selectedOptions
          )

        let variantId = selectedVariant.id

        this.setVariantPrice(
          selectedVariant.price,
          selectedVariant.compareAtPrice
        )

        this.selectedVariantAvailable = selectedVariant.available
        this.selectedVariantId = variantId
      },

      updateQuantity(quantity) {
        this.quantity = quantity
      },

      onAddToCart(event, variantId) {
        const client = this.shopifyClient
        const checkout = this.checkout
        const quantity = this.quantity
        const lineItems = [{variantId, quantity}]
        const cartBtn = event.target

        this.addToCart({client, checkout, lineItems, cartBtn})

        window.setTimeout(() => {
          this.openCart()
          this.hideVariants()
        }, 500)
      },

      openCart() {
        this.$emit('openCart')
      }
    }
  }
</script>

<style lang="sass">
  @import '../../stylesheets/base/variables'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/base/theme'

  .thumbnail
    background: #fff
    border-radius: 5px
    object-fit: contain

  .product-actions
    transition: all .2s ease-in-out
    border-radius: 5px
    font-size: 12px
    padding: 8px
    transform: scale(0.97)
    display: flex
    justify-content: space-between
    align-items: center

    @include themify($themes)
      background: themed('shop-product-actions')

    .sprite
      width: 20px
      height: 20px

      @include themify($themes)
        fill: themed('text')

  .quick-add
    display: flex
    align-items: center
    padding: 5px
    font-family: $font-body
    font-weight: bold
    text-transform: uppercase
    font-size: 11px
    border-radius: 3px
    border: none
    transition: all .3s ease-in-out

    @include themify($themes)
      background: themed('btn-background')
      color: themed('btn-text')

    .sprite
      @include themify($themes)
        fill: themed('btn-text')
        padding: 1px

    span
      display: none

      @include media($media-gt-large)
        display: block
        margin-left: 5px

  .product-contact
    transition: all .3s ease-in-out

    .sprite
      width: 25px
      height: 25px

  .variant-options
    position: fixed
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.5)
    z-index: 500
    top: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center

    .btn
      transition: opacity .2s ease-in-out

      &.is-disabled
        opacity: 0.25
        pointer-events: none

    hr
      margin: $gap-large 0

    .inner
      background: #fff
      text-align: center
      border-radius: 5px
      box-shadow: $content-shadow
      width: 90%
      max-height: 90%
      overflow: auto
      padding: $gap-medium
      -webkit-overflow-scroll: touch

      @include media($media-gt-medium)
        width: 600px

  .no-results
    text-align: center
</style>
