import * as types from './mutations/types'
import * as Shopify from '@/lib/shopify'

export default {
  createShopifyClient({commit}, {domain, storefrontAccessToken}) {
    const client = Shopify.createShopifyClient(domain, storefrontAccessToken)
    commit(types.SET_CLIENT, client)
  },

  getShopPolicies({commit}, {client}) {
    commit(types.REQUEST_SHOP_POLICIES)

    return Shopify.getShopPolicies(client)
      .then((shopPolicies) => {
        commit(types.RECEIVE_SHOP_POLICIES, {shopPolicies, getAt: Date.now()})
      })
      .catch(() => {
        console.error('Cant get shop')
        commit(types.RECEIVE_SHOP_POLICIES, {shopPolicies: {}, getAt: false})
      })
  },

  getCollections({commit}, {client}) {
    commit(types.REQUEST_COLLECTIONS)

    return Shopify.getCollections(client)
      .then((collections) => {
        commit(types.RECEIVE_COLLECTIONS, {collections, getAt: Date.now()})
      })
      .catch(() => {
        console.error(`Cannot get collections.`)
        commit(types.RECEIVE_COLLECTIONS, {collections: [], getAt: false})
      })
  },

  getCollection({commit}, {client, slug}) {
    commit(types.REQUEST_COLLECTION)

    // TODO: handle collection products

    return Shopify.getCollection(client, slug)
      .then((collection) => {
        commit(types.RECEIVE_COLLECTION, {collection})
      })
      .catch(() => {
        console.error(`Cannot get collection: slug: ${slug}`)
      })
  },

  getProducts({commit}, {client}) {
    commit(types.REQUEST_PRODUCTS)

    return Shopify.getProducts(client)
      .then((products) => {
        commit(types.RECEIVE_PRODUCTS, {products, getAt: Date.now()})
      })
      .catch(() => {
        console.error('Cannot get products')
        commit(types.RECEIVE_PRODUCTS, {products: [], getAt: false})
      })
  },

  getProduct({commit}, {client, slug}) {
    commit(types.REQUEST_PRODUCT)

    return Shopify.getProduct(client, slug)
      .then((product) => {
        commit(types.RECEIVE_PRODUCT, {product})
      })
      .catch(() => {
        console.error('Cannot get product')
      })
  },

  createCheckout({commit}, {client}) {
    commit(types.REQUEST_CHECKOUT)

    return Shopify.createCheckout(client)
      .then((checkout) => {
        commit(types.RECEIVE_CHECKOUT, {checkout})
      })
      .catch(() => {
        console.error('Cant create checkout')
      })
  },

  getCheckout({commit}, {client, id}) {
    commit(types.REQUEST_CHECKOUT)

    return Shopify.getCheckout(client, id)
      .then((checkout) => {
        commit(types.RECEIVE_CHECKOUT, {checkout})
      })
      .catch(() => {
        console.error(`Cant get checkout - id: ${id}`)
      })
  },

  addToCart({commit}, {client, checkout, lineItems, cartBtn}) {
    commit(types.REQUEST_CHECKOUT)

    const checkoutId = checkout.id
    cartBtn.innerHTML = 'Adding...'

    return Shopify.addToCart(client, checkoutId, lineItems)
      .then((checkout) => {
        commit(types.RECEIVE_CHECKOUT, {checkout})
        cartBtn.innerHTML = 'Added!'

        window.setTimeout(() => {
          cartBtn.innerHTML = 'Add to cart'
        }, 1000)
      })
      .catch(() => {
        console.error(
          `Cant add line items - checkoutId: ${checkoutId} lineItems: ${lineItems}`
        )
      })
  },

  removeFromCart({commit}, {client, checkout, lineItems}) {
    commit(types.REQUEST_CHECKOUT)

    const checkoutId = checkout.id

    return Shopify.removeFromCart(client, checkoutId, lineItems)
      .then((checkout) => {
        commit(types.RECEIVE_CHECKOUT, {checkout})
      })
      .catch(() => {
        console.error(
          `Cant remove line items - checkoutId: ${checkoutId} lineItems: ${lineItems}`
        )
      })
  }
}
