<template>
  <nav class="navigation">
    <div class="inner">
      <Burger ref="burger" @toggleNav="toggleNav" />

      <router-link
        to="/"
        :class="['logo', {'is-large': largeLogo}, {'has-image': site.logo_versions.length}]">

        <ResponsiveImage
          v-if="site.logo_versions.length"
          :width="336"
          :imageVersions="site.logo_versions"/>

        <h1 v-else>{{ logoText }}</h1>
      </router-link>

      <a class="item-action mobile" v-if="!hasShop && hasPhone" :href="phoneLink">
        <Sprite name="phone" />
      </a>

      <div
        v-if="hasShop"
        @click="toggleCart"
        class="item-action mobile">
        <sprite name="cart"></sprite>
        <span v-if="cartHasItems" class="cart-bubble">{{cartItemCount}}</span>
      </div>

      <div class="menu-items-wrapper" :class="{'is-active': navActive}">
        <ul class="menu-items">
          <li class="menu-item item-shop" v-if="hasShop">
            <router-link class="menu-item-link" @click.native="closeNav" :to="{name: 'shop.products'}">
              Shop
              <div class="link-border" :style="{background: accentColor}"></div>
            </router-link>

            <div class="desktop-only">
              <ShopMenuDesktop/>
            </div>

            <div v-if="hasCollections" class="mobile-only mobile-collections-list">
              <CollectionsList @click.native="closeNav" :collections="collectionsList"/>

              <ul class="collections-list">
                <li class="collection-link">
                  <router-link
                    @click.native="closeNav"
                    :to="{name: 'shop.collections'}">
                    All Collections
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="menu-item" v-for="item in navItems" :key="item.path">
            <router-link class="menu-item-link" @click.native="closeNav" :to="item.path">
              {{ item.name }}
              <div class="link-border" :style="{background: accentColor}"></div>
            </router-link>
          </li>

          <li class="menu-item item-blog" v-if="hasBlog">
            <router-link class="menu-item-link" @click.native="closeNav" :to="{name: 'blog'}">
              Blog
              <div class="link-border" :style="{background: accentColor}"></div>
            </router-link>
          </li>

          <li class="menu-item item-action item-phone desktop" v-if="hasPhone">
            <a class="menu-item-link" :href="phoneLink">
              <Sprite name="phone" />
              {{ phoneNumber }}
            </a>
          </li>

          <li class="menu-item item-search" v-if="hasShop">
            <div
              class="menu-item-link"
              @click="toggleSearch">
              <sprite name="search"></sprite>
            </div>
          </li>

          <li class="menu-item item-cart desktop" v-if="hasShop">
            <div
              class="menu-item-link"
              @click="toggleCart"
              :class="{'cart-has-items': cartHasItems}">
              <sprite name="cart"></sprite>
              <span v-if="cartHasItems" class="cart-bubble">{{cartItemCount}}</span>
            </div>
          </li>

          <li>
            <div class="mobile-close" @click="closeNav">
              <Sprite name="close" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import Burger from '@/components/Burger'
import ResponsiveImage from '@/components/ResponsiveImage'
import Sprite from '@/components/Sprite'
import CollectionsList from '@/components/shop/CollectionsList'
import ShopMenuDesktop from '@/components/shop/ShopMenuDesktop'

// TODO: Extract cart junk to own component

export default {
  name: 'Navigation',

  data() {
    return {
      navActive: false
    }
  },

  props: {
    searchActive: Boolean
  },

  computed: {
    ...mapGetters(['hasShop', 'hasBlog', 'navItems', 'site']),
    ...mapState(['articlesList']),
    ...mapState('shop', [
        'checkout',
        'collectionsList',
        'listedCollectionsAt',
        'shopifyClient']),

    cartItemCount() {
      return this.checkout.lineItems ? this.checkout.lineItems.length : false
    },

    logoText() {
      return this.businessPreview ? this.businessPreview : this.site.name
    },

    hasCollections() {
      return this.collectionsList.length >= 1
    },

    accentColor() {
      return this.site.background_color ? this.site.background_color : '#cecece'
    },

    largeLogo() {
      return this.site.large_logo
    },

    cartHasItems() {
      return this.cartItemCount >= 1
    },

    hasPhone() {
      return !!this.phone.length
    },

    phone() {
      return this.site.contacts.filter(contact => {
        return contact.type === 'phone'
      })
    },

    phoneLink() {
      return this.hasPhone ? `tel:${this.phoneNumber}` : false
    },

    phoneNumber() {
      return this.hasPhone ? this.phone[0].value : false
    }
  },

  components: {
    Burger,
    Sprite,
    ResponsiveImage,
    ShopMenuDesktop,
    CollectionsList
  },

  methods: {
    ...mapActions('shop', ['getCollections']),

    toggleCart() {
      this.$emit('toggleCart')
    },

    toggleSearch() {
      this.$emit('toggleSearch', this.searchActive)
      this.closeNav()
    },

    toggleNav() {
      this.navActive = !this.navActive
      this.$emit('navActive', this.navActive)
    },

    closeNav() {
      this.navActive = false
      this.$emit('navActive', false)
      this.$refs.burger.closeBurger()
    }
  },

  mounted() {
    let shopifyDomain = this.site.shopify_url

    if(shopifyDomain) {
      if (!this.listedCollectionsAt) {
        let client = this.shopifyClient

        this.getCollections({client})
      }
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/helpers/extends'
@import '../stylesheets/base/theme'

.navigation
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 1000

  .inner
    position: relative
    height: $min-nav-height

    @include themify($themes)
      background: themed('header')
      color: themed('text')
      fill: themed('text')

    @include media($media-gt-large)
      padding: 0 $gap-medium
      background: none!important

  .menu-items-wrapper
    min-height: calc(100vh - #{$min-nav-height})
    transition: all .3s ease-in-out
    transform: translate(-100%, $min-nav-height)

    @include themify($themes)
      background: themed('mobile-nav-bg')

    &.is-active
      transform: translate(0, $min-nav-height)

    @include media($media-gt-large)
      background: none!important
      min-height: 0
      transform: none
      transition: none

      &.is-active
        transform: none

  .menu-items
    list-style: none
    margin: 0
    padding: 0
    overflow: auto
    -webkit-overflow-scrolling: touch
    max-height: calc(100vh - #{$min-nav-height})
    text-align: center

    @include themify($themes)
      color: themed('text')
      fill: themed('text')

    @include media($media-gt-large)
      background: none!important
      overflow: visible

  .menu-item
    transition: all .3s ease-in-out

    @include media($media-gt-large)
      line-height: $min-nav-height

      &:hover
        @include themify($themes)
          background: themed('menu-link-hover-bg')

          .menu-item-link
            color: themed('menu-link-hover-text')
            fill: themed('menu-link-hover-text')

    .menu-item-link
      &.is-active
        @include themify($themes)
          background: themed('menu-link-hover-bg')
          color: themed('menu-link-hover-text')
          fill: themed('menu-link-hover-text')


    .menu-item-link, .mobile-collections-list .collection-link a
      display: block
      padding: $gap-small
      text-decoration: none
      font-size: 12px
      position: relative

      @include media($media-gt-large)
        padding: 0 $gap-medium

        &.is-active
          .link-border
            width: 30px

  .logo
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    text-decoration: none
    max-width: 100%
    text-align: center

    &.has-image
      &.is-large
        transform: scale(2.3) translate(-50%, 0)
        transform-origin: 0 50%
        max-width: 30%

        img
          top: 0

        @include media($media-gt-large)
          transform: scale(2) translate(0, 0)
          transform-origin: 0 0

          img
            max-height: 100px
            max-width: 200px
            margin: 0
    img
      max-height: calc(#{$min-nav-height} - #{$gap-medium})
      display: block
      position: relative
      margin: auto

    @include themify($themes)
      color: themed('text')

    @include media($media-gt-large)
      position: initial
      transform: none

      .responsive-image
        display: block

  @include media($media-gt-large)
    position: relative

    @include themify($themes)
      background: themed('header')
      color: themed('text')
      fill: themed('text')

    .inner
      max-width: $max-width
      margin: auto
      display: flex
      align-items: center
      justify-content: space-between

      .has-large-logo &
        align-items: start

    .menu-items
      display: flex
      padding: 0
      transform: none
      transition: none
      background: none

      &.is-active
        transform: none

.mobile-close
  display: none
  cursor: pointer

.item-action
  cursor: pointer

  &.mobile
    @extend %mobile-only
    position: absolute
    top: 50%
    transform: translateY(-50%)
    right: $gap-medium

    .sprite
      width: 30px
      height: 30px

  &.desktop
    @extend %desktop-only
    position: relative

    .sprite
      position: relative
      top: 5px
      left: 0
      width: 20px
      height: 20px
      margin-right: 5px

.mobile-collections-list
  .thumbnail
    display: none

.item-cart,
.item-search
  position: relative
  cursor: pointer

  .sprite
    width: 25px
    height: 25px
    position: relative
    top: 5px

.cart-bubble
  font-weight: bold
  position: relative
  top: -8px
  left: 2px

  @include media($media-gt-large)
    top: 0

.link-border
  position: absolute
  width: 30px
  left: 50%
  bottom: 20px
  transform: translateX(-50%)
  width: 0
  height: 2px
  transition: width .3s ease-in-out
</style>