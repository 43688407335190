<template lang="html">
  <div class="site-blocked">
    <p>This site is currently offline.</p>
  </div>
</template>

<script>
export default {
  name: 'site-blocked'
}
</script>

<style lang="sass">
.site-blocked
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: #fff
  text-align: center
  padding: 10px
  z-index: 1000

  p
    color: #000!important
</style>
