<template>
  <svg
    version="1.1"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 100"
    style="enable-background:new 0 0 595.3 100;"
    xml:space="preserve"
  >
    <path
      class="bottom-svg"
      d="M579,70.2c-10.9-12.5-21.8-9.8-32.7,7.9c-6.8-4.1-13.6-4.2-20.5-0.4c-12.1-21.6-24.1-23.5-36.2-5.7
  c-10.3-10.9-20.6-9.2-30.9,5.1c-7.7-5.8-15.4-6.2-23.1-1c-12.9-22.1-25.8-21.5-38.8,1.6c-9.1-5.1-18.2-3.2-27.3,5.7
  c-7.7-4.3-15.3-4.6-23-1c-10.3-13.4-20.6-16.1-31-7.8c-12.6-20.5-25.2-19.5-37.9,3.1c-6.3-3.5-12.5-3.7-18.8-0.6
  c-12.9-27.3-25.7-29.5-38.6-6.4c-8.1-7.7-16.2-7.7-24.2,0c-10.1-18.1-20.2-20.6-30.3-7.6c-12.3-26-24.5-24.1-36.8,5.9
  c-7.4-5.9-14.8-5.3-22.2,1.8c-11.8-21.1-23.5-21.1-35.3,0c-7.7-7.4-15.4-7.7-23.2-1c-12-25.4-24-26.4-36.1-2.8
  c-14.1-15.8-28.1-4.8-42.2,33H0h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8h29.8H506h29.8h29.8
  h29.8H625C609.7,56.2,594.3,46.3,579,70.2z"
    />
    <path
      class="top-svg"
      d="M-29.8,0v100h0c14.1-37.8,28.1-48.8,42.2-33c12-23.5,24-22.6,36.1,2.8c7.7-6.7,15.4-6.4,23.2,1
  c11.8-21.1,23.5-21.1,35.3,0c7.4-7.1,14.8-7.7,22.2-1.8c12.3-29.9,24.5-31.9,36.8-5.9c10.1-13,20.2-10.5,30.3,7.6
  c8.1-7.7,16.2-7.7,24.2,0c12.9-23,25.7-20.9,38.6,6.4c6.3-3.1,12.5-2.9,18.8,0.6c12.6-22.6,25.2-23.7,37.9-3.1
  c10.3-8.2,20.6-5.6,31,7.8c7.7-3.6,15.3-3.3,23,1c9.1-8.9,18.2-10.8,27.3-5.7c12.9-23.2,25.8-23.7,38.8-1.6c7.7-5.2,15.4-4.8,23.1,1
  c10.3-14.3,20.6-16,30.9-5.1c12.1-17.8,24.1-15.9,36.2,5.7c6.8-3.8,13.6-3.7,20.5,0.4c10.9-17.8,21.8-20.4,32.7-7.9
  c15.3-24,30.7-14,46,29.8l0,0V0H-29.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'clouds-seperator'
}
</script>