<template>
  <div class="page-blog-article" v-if="article">
    <div class="page-section article-heading">
      <div class="container">
        <Parallax>
          <h1>{{article.heading}}</h1>
        </Parallax>
      </div>
    </div>

    <div class="page-section">
      <div class="container">
        <div class="content-bg">
          <div class="article-content">
            <ResponsiveImage
              v-if="article.featured_image_versions"
              :imageVersions="article.featured_image_versions"
              :description="`${article.heading} image`"
              width="1100"/>

            <div v-html="article.content_html"></div>
          </div>

          <div class="article-controls">
            <div class="sticky-container">
              <h2>Share Article</h2>
              <SocialShare :url="blogUrl"/>

              <hr>

              <div class="article-navigation">
                <div v-if="prevArticle">
                  <router-link
                    class="btn article-btn prev"
                    :to="{ name: 'blog.article', params: {slug: prevArticle.slug}}">
                    <Sprite name="arrow"/>
                    Prev
                  </router-link>
                </div>

                <router-link class="btn article-btn" :to="{ name: 'blog'}">
                  All Articles
                </router-link>

                <div v-if="nextArticle">
                  <router-link
                    class="btn article-btn next"
                    :to="{ name: 'blog.article', params: {slug: nextArticle.slug}}">
                    Next
                    <Sprite name="arrow"/>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewsLetter class="page-section"/>
    <FooterTop class="page-section footer-top"/>
    <FooterBottom class="page-section footer-bottom"/>
  </div>
</template>

<script>
import page from '@/mixins/page'

import Parallax from '@/components/Parallax'
import SocialShare from '@/components/SocialShare'
import ResponsiveImage from '@/components/ResponsiveImage'
import Sprite from '@/components/Sprite'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'BlogArticle',

  mixins: [page],

  props: {
    slug: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pageSlug: 'blog-article'
    }
  },

  components: {
    Sprite,
    Parallax,
    SocialShare,
    ResponsiveImage,
    NewsLetter,
    FooterTop,
    FooterBottom
  },

  computed: {
    ...mapState(['articlesBySlug', 'hostName']),
    ...mapGetters(['siteId']),

    title() {
      return this.article.heading
    },

    article() {
      return this.articlesBySlug[this.slug] || {}
    },

    prevArticle() {
      return this.article.previous
    },

    nextArticle() {
      return this.article.next
    },

    blogUrl() {
      return `https://${this.hostName}/blog/${this.slug}`
    },

    shouldGetArticle() {
      return true
    },

    articleStale() {
      if (!this.article.__readAt) {
        return true
      }

      return Date.now() - this.article.__readAt > 6000
    },

    metaDescription() {
      // TODO: This will be article.excerpt
      return this.article.content_html.replace(/(<([^>]+)>)/ig,"").substring(0, 155)
    },

    meta() {
      return [
        {vmid: 'description', name: 'description', property: 'description', content: this.metaDescription},

        {vmid: 'og:title', property: 'og:title', content: this.article.heading},
        {vmid: 'og:url', property: 'og:url', content: this.blogUrl},
        {vmid: 'og:image', property: 'og:image', content: this.article.featured_image},
        {vmid: 'og:description', property: 'og:description', content: this.metaDescription}
      ]
    }
  },

  serverPrefetch() {
    return new Promise((resolve, reject) => {
      // No need to ask the server if we cant find page id in stored page path
      // to id map!
      if (!this.article) {
        reject({code: 404})
      }

      // Try to get the page, pass on the error status from api on error
      this.getSiteArticle({siteId: this.siteId, slug: this.slug})
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject({code: error.response.status})
        })
    })
  },

  methods: {
    ...mapActions(['getSiteArticle']),

    getData() {
      if (this.shouldGetArticle) {
        const siteId = this.siteId
        const slug = this.slug

        this.getSiteArticle({siteId, slug})
      }
    }
  },

  mounted() {
    this.getData()
  },

  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'
@import '../../stylesheets/helpers/extends'

.page-blog-article
  .content-bg
    @include media($media-gt-medium)
      display: flex

      .article-content
        flex-basis: percentage(3/4)
        padding-right: $gap-x-large

      .article-controls
        flex-basis: percentage(1/4)

        hr
          margin: $gap-large 0

    .article-controls
      margin-top: $gap-x-large

      @include media($media-gt-medium)
        margin-top: 0

      .btn
        display: flex
        align-items: center
        font-size: 10px

    img
      width: 100%
      max-width: 100%
      margin: $gap-medium 0
      border-radius: 5px

  .article-content
    h2, h3
      margin-top: $gap-large
      margin-bottom: $gap-x-small

    p
      margin-top: 0

  .sticky-container
    position: sticky
    top: calc(#{$min-nav-height} + #{$gap-medium})

  .article-heading
    text-align: center

    .has-large-logo &
      padding-top: $gap-x-large

  .article-navigation
    display: flex
    justify-content: center

    .sprite
      width: 12px
      height: 12px

      @include themify($themes)
        fill: themed('btn-text')

    .article-btn
      padding: 10px
      margin: 0 $gap-x-small

    .prev
      .sprite
        transform: scaleX(-1)
</style>
