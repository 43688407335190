<template lang="html">
  <div :class="separatorClasses">
    <div :is="type"/>
  </div>
</template>

<script>
import Slant from '@/components/lists/Separators/Slant'
import Clouds from '@/components/lists/Separators/Clouds'
import Hills from '@/components/lists/Separators/Hills'
import Triangle from '@/components/lists/Separators/Triangle'
import Waves from '@/components/lists/Separators/Waves'

export default {
  name: 'SectionSeparator',

  props: ['separator'],

  components: {
    Slant,
    Clouds,
    Hills,
    Triangle,
    Waves
  },

  computed: {
    separatorOptions() {
      let item = this.separator.list_items[0]
      return JSON.parse(item.value)
    },

    type() {
      return this.separatorOptions.style
    },

    separatorClasses() {
      let flipY = this.separatorOptions.flip_vertical
      let flipX = this.separatorOptions.flip_horizontal
      let flipBoth = flipY && flipX

      return [
        'section-separator',
        {'flip-vertical': flipY},
        {'flip-horizontal': flipX},
        {'flip-both': flipBoth}
      ]
    }
  }
}
</script>

<style lang="sass">
  @import '../../stylesheets/base/theme'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/base/variables'

  .section-separator
    svg
      width: 100%
      padding: 0
      display: block
      position: relative
      height: $separator-height-mobile

      @include media($media-gt-medium)
        height: $separator-height-desktop

    &.flip-vertical
      svg
        transform: scaleY(-1)

    &.flip-horizontal
      transform: scaleX(-1)

    &.flip-both
      transform: scaleX(-1, -1)
</style>