<template>
  <svg
    version="1.1"
    id="Layer_1"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 100"
    style="enable-background:new 0 0 595.3 100;"
    xml:space="preserve"
  >
    <path class="top-svg" d="M-6-1L297.6,99L601.2-1H-6z" />
    <polygon
      class="bottom-svg"
      points="297.6,99 0,1 0,99.9 595.3,99.9 595.3,1 "
    />
  </svg>
</template>

<script>
export default {
  name: 'triangle-seperator'
}
</script>
