<template lang="html">
  <svg class="sprite">
    <use :xlink:href="path"></use>
  </svg>
</template>

<script>
const SPRITE_PATH = require('../assets/svg/sprite.symbol.svg')

export default {
  name: 'Sprite',

  props: {
    name: String
  },

  computed: {
    path() {
      return `${SPRITE_PATH}#${this.name}`
    }
  }
}
</script>

<style lang="sass">
.sprite
  width: 100%
  height: 100%
</style>
