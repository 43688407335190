import { render, staticRenderFns } from "./App.vue?vue&type=template&id=86d727e6&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./App.vue?vue&type=style&index=0&lang=sass&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "9b347f76"
  
)

export default component.exports