import { render, staticRenderFns } from "./CollectionsList.vue?vue&type=template&id=19b5011e&lang=html&"
import script from "./CollectionsList.vue?vue&type=script&lang=js&"
export * from "./CollectionsList.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./CollectionsList.vue?vue&type=style&index=0&lang=sass&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "5ddf4f37"
  
)

export default component.exports