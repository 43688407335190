<template>
  <section @click="toggleNav" class="burger-wrapper">
    <button class="burger" :class="{'is-active': burgerActive}">
      Mobile menu
      <span></span>
      <span></span>
      <span></span>
    </button>
  </section>
</template>

<script>
export default {
  name: 'Burger',

  data() {
    return {
      burgerActive: false
    }
  },

  methods: {
    toggleNav() {
      this.burgerActive = !this.burgerActive
      this.$emit('toggleNav')
    },

    closeBurger() {
      this.burgerActive = false
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'

.burger-wrapper
  display: block
  position: absolute
  top: 50%
  transform: translateY(-50%)
  left: $gap-medium

  @include media($media-gt-large)
    display: none

button
  &.burger
    background: none

.burger
  height: 20px
  width: 30px
  left: 0
  cursor: pointer
  display: block
  border: none
  padding: 0
  background: none!important
  text-indent: -9999px

  &:focus
    outline: none

  span
    display: block
    position: absolute
    height: 2px
    width: 30px
    opacity: 1
    left: 0
    transform: rotate(0deg)
    transition: all .3s ease-in-out

    @include themify($themes)
      background: themed('text')

    &:nth-child(1)
      top: 0

    &:nth-child(2)
      top: 9px

    &:nth-child(3)
      top: 18px

  &.is-active
    span
      &:nth-child(1)
        transform: rotate(45deg)
        top: 7px
        left: 2px

      &:nth-child(2)
        width: 0%
        opacity: 0

      &:nth-child(3)
        transform: rotate(-45deg)
        top: 7px
        left: 2px
</style>
