<template>
  <div class="page page-shop-collections">
    <div class="page-section" v-if="collectionsList">
      <div class="container">
        <Parallax>
          <div class="shop-heading">
            <h1>All Collections</h1>
          </div>
        </Parallax>

        <CollectionsList :collections="collectionsList"/>
      </div>
    </div>

    <NewsLetter class="page-section"/>
    <FooterTop class="page-section footer-top"/>
    <FooterBottom class="page-section footer-bottom"/>
  </div>
</template>

<script>
import page from '@/mixins/page'

import {mapState, mapActions} from 'vuex'
import CollectionsList from '@/components/shop/CollectionsList'
import Parallax from '@/components/Parallax'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

export default {
  name: 'ShopCollections',

  mixins: [page],

  data() {
    return {
      title: 'Collections',
      allCollectionsLoaded: false
    }
  },

  components: {
    CollectionsList,
    Parallax,
    NewsLetter,
    FooterTop,
    FooterBottom
  },

  computed: {
    ...mapState('shop', [
      'collectionsList',
      'listedCollectionsAt',
      'shopifyClient'
    ])
  },

  methods: {
    ...mapActions('shop', ['getCollections']),

    fetchNextPage() {
      let client = this.shopifyClient

      client.fetchNextPage(this.collectionsList).then((nextCollections) => {
        let hasNext = nextCollections.model.length > 0

        if (!hasNext) {
          this.collectionsLoaded = true
        } else {
          nextCollections.model.forEach((product) => {
            this.collectionsList.push(product)
          })
        }
      })
    },

    paginate() {
      let timer

      timer = window.setInterval(() => {
        if (!this.allProductsLoaded) {
          this.fetchNextPage()
        }
      }, 1500)

      if (this.allProductsLoaded) {
        clearInterval(timer)
      }
    }
  },

  mounted() {
    // if (!this.listedCollectionsAt) {
      let client = this.shopifyClient

      this.getCollections({client})
      this.paginate()
    // }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'

.page-shop-collections
  .collections-list
    list-style: none
    padding: $gap-medium
    margin-top: $gap-large

    @include themify($themes)
      background: themed('content-bg')

    li
      padding: $gap-large 0
      border-bottom: 1px solid

      @include themify($themes)
        border-color: themed('border-color')

      &:last-child
        border: none

      p
        &.has-image
          margin-top: 20px

          @include media($media-gt-medium)
            margin: 0

      @include media($media-gt-medium)
        padding: 0
        border: none

    a
      text-decoration: none
      font-size: 16px
      display: flex
      width: 100%
      height: 100%
      align-items: center
      flex-direction: column
      justify-content: center
</style>
