import Vue from 'vue'

import * as types from '@/store/mutation_types'

const READ_AT_KEY = '__read_at'

function loading(state) {
  let count = state.activeRequests + 1
  Vue.set(state, 'activeRequests', count)
}

function loaded(state) {
  let count = state.activeRequests - 1
  Vue.set(state, 'activeRequests', count)
}

export default {
  /**
   * Set the current site in store
   *
   * @param state {object} current state
   * @param site {object} site object
   */
  [types.SET_SITE](state, site) {
    Vue.set(state, 'site', site)
  },

  /**
   * Add a page to store
   *
   * Pages are cached in vuex until user reloads site
   *
   * @param state {object} current state
   * @param data {object}
   * @param data.id {string} page id
   * @param data.page {object} page object
   */
  [types.ADD_PAGE](state, {id, page}) {
    Vue.set(state.pages, id, page)
  },

  /**
   * Count active ajax requests
   * deferrs to loading functions until we can refactor the store to not need this
   *
   * TODO: refactor this out to have REQUEST and REQUESTED actions per resource
   *
   * @param state {object} current state
   * @param value {boolean} true for opening connection, false for closing
   */
  [types.LOADING](state, value) {
    let fn = value ? loading : loaded
    fn(state)
  },

  // Articels mutations

  [types.REQUEST_SITE_ARTICLE](state) {
    loading(state)
  },

  [types.REQUESTED_SITE_ARTICLE](state) {
    loaded(state)
  },

  [types.REQUEST_SITE_ARTICLES](state) {
    loading(state)
  },

  [types.REQUESTED_SITE_ARTICLES](state) {
    loaded(state)
  },

  [types.RECEIVE_SITE_ARTICLE](state, {readAt, slug, article}) {
    article[READ_AT_KEY] = readAt
    Vue.set(state.articlesBySlug, slug, article)
  },

  [types.RECEIVE_SITE_ARTICLES](state, {readAt, articles}) {
    Vue.set(state, 'articlesReadAt', readAt)
    Vue.set(state, 'articlesList', articles)

    articles.forEach((article) => {
      article[READ_AT_KEY] = readAt

      Vue.set(state.articlesBySlug.slug, article)
    })
  }
}
