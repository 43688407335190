import Vue from 'vue'
import Vuex from 'vuex'

import * as getters from '@/store/getters'

import mutations from '@/store/mutations'
import actions from '@/store/actions'

import shop from './modules/shop'

Vue.use(Vuex)

let plugins = []

export function createStore() {
  /* elsint-disabel no-new */
  return new Vuex.Store({
    plugins,
    actions,
    getters,
    mutations,
    modules: {
      shop
    },
    state: {
      activeRequests: 0,

      site: {},
      pages: {},
      hostName: '',

      articlesBySlug: {},
      articlesList: [],
      articlesReadAt: false
    }
  })
  /* elsint-enable no-new */
}
