<template>
  <div class="shop-product-option-item">
    <h4 class="option-name">{{ option.name }}</h4>

    <ul>
      <li v-for="value in option.values" class="option-item" :key="value.id">
        <button
          @click="selectValue(option, value)"
          class="btn btn-variant"
          :class="activeClass(value)">
          {{ value.value }}
        </button>
      </li>
    </ul>

    <hr>
  </div>
</template>

<script>
export default {
  name: 'shopProductOptionsItem',

  props: {
    option: {
      required: true
    }
  },

  data() {
    return {
      selectedValue: null
    }
  },

  computed: {
    activeClass() {
      return (value) => {
        if(this.selectedValue) {
          return value.value === this.selectedValue ? 'is-active' : ''
        } else {
          return false
        }
      }
    }
  },

  methods: {
    selectValue(option, variant) {
      this.selectedValue = variant.value
      this.$emit('updateVariant', option, variant)
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'

.shop-product-option-item
  ul
    display: flex
    justify-content: center
    flex-wrap: wrap

  .option-item
    .btn-variant
      margin: $gap-small
      padding: $gap-small $gap-medium
      text-transform: uppercase
      letter-spacing: 1px
      opacity: 0.3
      transition: all .3s ease-in-out

    .is-active
      transform: scale(1.2)
      opacity: 1
</style>
