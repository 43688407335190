<template lang="html">
  <div class="shop-search">
    <div class="search-inner" @close="closeOverlay">
      <form @submit.prevent="search">
        <div class="input">
          <input placeholder="Search for a product..." v-model="query"/>
          <button @click="search" class="btn">
            <Sprite name="search"/>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/Sprite'

export default {
  name: 'ShopSearch',

  data() {
    return {
      query: ''
    }
  },

  components: {
    Sprite
  },

  computed: {
    searchUrl() {
      return `/shop?search=${this.query}`
    }
  },

  methods: {
    search() {
      this.closeOverlay()
      this.$router.push({ path: '/shop', query: {query: this.query}})
    },

    closeOverlay() {
      this.$emit('closeOverlay')
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'

.shop-search
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  align-items: center
  justify-content: center
  width: 95%
  z-index: 1000

  @include media($media-gt-medium)
    width: 50%

  .search-inner
    box-shadow: $content-shadow
    padding: $gap-large $gap-medium
    border-radius: 5px
    text-align: center
    width: 100%

    @include themify($themes)
      background: themed('shop-search-overlay')

    @include media($media-gt-medium)
      padding: $gap-large

    .input
      display: flex
      align-items: center

    input
      font-size: 16px

      &:focus
        outline: none

    .btn
      margin-left: $gap-medium
      padding: $gap-small $gap-medium

      .sprite
        width: 20px
        height: 20px

        @include themify($themes)
          fill: themed('btn-text')
</style>
