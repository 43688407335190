<template lang="html">
  <div>
    <div class="top-footer">
      <div class="col">
        <h2>
          <span>{{ site.name }}</span>
          Links
        </h2>

        <p v-if="hasShop">
          <router-link :to="{name: 'shop.products'}">
            Shop
          </router-link>
        </p>

        <p v-for="item in navItems" :key="`footer-${item.path}`">
          <router-link :to="item.path">
            {{ item.name }}
          </router-link>
        </p>

        <p v-if="hasBlog">
          <router-link :to="{name: 'blog'}">
            Blog
          </router-link>
        </p>
      </div>

      <div class="col" v-if="hasSocial">
        <h2>
          <span>Stay</span>
          Social
        </h2>
        <p>
          Be sure to follow us on social media to keep up to date with the
          latest news and offers!
        </p>

        <div class="social-links">
          <SocialLink
            v-for="platform in socialPlatforms"
            :key="platform.key"
            :platform="platform"
          />
        </div>
      </div>

      <div class="col" v-if="hasShop">
        <h2>
          <span>{{ site.name }}</span>
          Policies
        </h2>

        <p><router-link :to="{name: 'terms-policy'}">Terms</router-link></p>
        <p><router-link :to="{name: 'privacy-policy'}">Privacy</router-link></p>
        <p>
          <router-link :to="{name: 'refund-policy'}">Refund Policy</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SocialLink from '@/components/SocialLink'

export default {
  name: 'FooterTop',

  components: {
    SocialLink
  },

  computed: {
    ...mapGetters(['navItems', 'site', 'hasShop', 'hasBlog']),

    year() {
      return new Date().getFullYear()
    },

    hasSocial() {
      return !!this.socialPlatforms.length
    },

    socialPlatforms() {
      return this.site.contacts.filter((contact) => {
        return contact.type === 'social'
      })
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'

.top-footer
  text-align: center
  padding: $gap-x-large 0
  max-width: $max-width

  h2
    margin-bottom: $gap-medium

    @include themify($themes)
      color: themed('text')

    span
      display: block

  a
    text-transform: uppercase

  p
    margin: 0 auto
    max-width: 400px

  .col
    padding: 0 $gap-medium $gap-x-large

    &:last-child
      padding-bottom: 0

  @include media($media-gt-medium)
    display: flex
    padding: 140px 0
    margin: auto

    .col
      flex: 1
      padding-bottom: 0

  .social-links
    display: flex
    align-items: center
    justify-content: center
    margin-top: $gap-medium

    .social-link
      transition: transform .2s ease-in-out
      margin: 0 8px

      &:hover
        transform: scale(1.15)

    .sprite
      width: 30px
      height: 30px
</style>
