<template>
  <div class="container">
    <Parallax>
      <div class="shop-heading">
        <h1 class="product-title">{{ product.title }}</h1>
        <h2>
          <ProductPrice
            :price="variantPrice"
            :compareAtPrice="variantComparePrice"/>
        </h2>
      </div>
    </Parallax>

    <div class="shop-product-detail" v-if="selectedOptions">
      <div class="col product-imgs">
        <ProductImages
          ref="carousel"
          v-if="this.product.images"
          :images="this.product.images"/>
      </div>

      <div class="col product-info">
        <p class="stock-message" v-html="selectOptionsMessage"></p>
        <hr>

        <product-options
          v-if="hasVariants"
          :options="options"
          @updateVariant="updateVariant">
        </product-options>

        <ProductQuantity @updateQuantity="updateQuantity"/>
        <hr>

        <div class="add-to-cart">
          <button
            id="btn-atc"
            :class="{ 'is-disabled': !selectedVariantAvailable }"
            class="btn"
            @click="onAddToCart($event)">
            {{cartButtonText}}
          </button>
        </div>

        <hr>
        <p v-html="product.descriptionHtml"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'

import ProductOptions from '@/components/shop/ProductOptions'
import ProductQuantity from '@/components/shop/ProductQuantity'
import ProductImages from '@/components/shop/ProductImages'
import ProductPrice from '@/components/shop/ProductPrice'
import Parallax from '@/components/Parallax'

import {mapActions, mapState} from 'vuex'

export default {
  name: 'shopProductDetail',

  mixins: [Page],

  data() {
    return {
      totalSelectedOptions: 0,
      quantity: 1,
      selectedOptions: {},
      selectedVariantAvailable: '',
      selectedVariantId: '',
      variantPrice: '',
      variantComparePrice: ''
    }
  },

  props: {
    product: {
      required: true
    }
  },

  components: {
    ProductOptions,
    ProductQuantity,
    ProductImages,
    ProductPrice,
    Parallax
  },

  methods: {
    ...mapActions('shop', ['addToCart']),

    updateVariant(option, variant) {
      this.selectedOptions[option.name] = variant.value
      this.getOptionsLength()

      if(this.allOptionsSelected) {
        this.getSelectedVariant()
      }
    },

    updateQuantity(quantity) {
      this.quantity = quantity
    },

    getOptionsLength() {
      let total = Object.keys(this.selectedOptions).length
      this.totalSelectedOptions = total
    },

    getSelectedVariant() {
      let selectedVariant =
        this.shopifyClient.product.helpers.variantForOptions(
          this.product,
          this.selectedOptions
        )

      let variantId = selectedVariant.id
      let imageSrc = selectedVariant.image.src

      this.setVariantPrice(
        selectedVariant.price,
        selectedVariant.compareAtPrice
      )

      this.selectedVariantAvailable = selectedVariant.available
      this.selectedVariantId = variantId

      if (imageSrc) {
        this.getVariantImage(imageSrc)
      }
    },

    handleSingleVariant() {
      if(this.totalVariants === 1) {
        let option = this.product.options[0]
        let variant = this.product.options[0].values[0]
        this.updateVariant(option, variant)
      }
    },

    onAddToCart() {
      const client = this.shopifyClient
      const checkout = this.checkout
      const variantId = this.selectedVariantId
      const quantity = this.quantity
      const lineItems = [{variantId, quantity}]
      const cartBtn = event.target

      this.addToCart({client, checkout, lineItems, cartBtn})

      window.setTimeout(() => {
        this.openCart()
      }, 500)
    },

    openCart() {
      this.$emit('openCart')
    },

    changeCartText(message) {
      this.$refs.cartButton.innerHTML = message
    },

    setVariantPrice(price, compareAtPrice) {
      if(price) {
        this.variantPrice = price
        this.variantComparePrice = compareAtPrice
      } else {
        this.handleSingleVariant()

        if(this.product.variants) {
          this.variantPrice = this.product.variants[0].price
          this.variantComparePrice = this.product.variants[0].compareAtPrice
        }
      }
    },

    getVariantImage(imageSrc) {
      if(this.totalVariants) {
        let carousel = this.$refs.carousel

        if(carousel) {
          carousel.selectImage(imageSrc)
        }
      }
    }
  },

  computed: {
    ...mapState(['hostName', 'site']),
    ...mapState('shop', ['shopifyClient', 'checkout']),

    hasVariants() {
      if(this.product.options) {
        return this.product.options[0].name !== 'Title'
      }
    },

    options() {
      return this.product.options
    },

    title() {
      return this.product.title
    },

    allOptionsSelected() {
      if(this.options) {
        return this.totalSelectedOptions === this.product.options.length
      } else {
        return false
      }
    },

    metaImage() {
      return this.product.images ? this.product.images[0].src : false
    },

    cartButtonText() {
      let selectText

      if(this.totalOptions > 1) {
        selectText = 'Select Options'
      } else {
        selectText = 'Select an option'
      }

      if(!this.allOptionsSelected) {
        return selectText
      } else if(!this.selectedVariantAvailable) {
        return 'Sold out'
      } else {
        return 'Add to cart'
      }
    },

    selectOptionsMessage() {
      let stockMessage

      if(!this.selectedVariantAvailable) {
        stockMessage = `<span class="out-of-stock">Sorry, out of stock!</span>`
      } else {
        stockMessage = `<span class="in-stock">We have this in stock!</span>`
      }

      if(this.totalOptions > 1) {
        if(!this.allOptionsSelected) {
          return `Please select your options and quantity.`
        } else {
          return stockMessage
        }
      } else {
        if(!this.allOptionsSelected) {
          return `Please select a <span class="lowercase">${this.variantName}</span> and quantity.`
        } else {
          return stockMessage
        }
      }
    },

    totalVariants() {
      if(this.product.variants) {
        return this.product.variants.length
      } else {
        return false
      }
    },

    variantName() {
      /**
       * If there is only one variant, return the name of it
       */
      if(this.totalOptions === 1) {
        return this.product.options[0].name
      } else {
        return false
      }
    },

    totalOptions() {
      if(this.product.options) {
        return this.product.options.length
      } else {
        return false
      }
    },

    meta() {
      return [
        {vmid: 'og:title', property: 'og:title', content: this.product.title},
        {vmid: 'og:url', property: 'og:url', content: `${this.hostName}/shop/products/${this.product.handle}` },
        {vmid: 'og:image', property: 'og:image', content: this.metaImage},
        {vmid: 'og:description', property: 'og:description', content: this.product.description}
      ]
    }
  },

  mounted() {
    this.setVariantPrice()
  },

  watch: {
    '$route'() {
      this.setVariantPrice()
    },

    product() {
      this.setVariantPrice()
    }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/helpers/extends'
@import '../../stylesheets/base/theme'

.container
  @include themify($themes)
    color: themed('text')

  .product-title, .product-price
    text-align: center
    display: block

  // .product-price
  //   margin: 0 auto $gap-large

.shop-product-detail
  position: relative
  padding: $gap-medium
  margin-top: $gap-large

  @include themify($themes)
    background: themed('content-bg')

  @include media($media-gt-large)
    display: flex
    padding: 0

    .col
      padding: 0

    .product-imgs
      flex-basis: 60%

  hr
    margin: 30px auto
    height: 1px

  .product-info
    padding: 20px 0

    @include media($media-gt-medium)
      flex-basis: 40%
      padding: $gap-large

      p
        &:first-child
          margin: 0

  .image-wrapper
    @include media($media-gt-medium)
      position: sticky
      top: $min-nav-height

  .product-banner
    background-size: 100% auto
    background-repeat: no-repeat

  .product-imgs
    display: flex
    flex-wrap: wrap
    background: #fff

    .img
      flex-basis: percentage(1/4)

    img
      max-width: 100%

  .add-to-cart
    text-align: center

    button
      font-size: 16px
      font-weight: bold
      min-width: 225px

      &.is-disabled
        opacity: 0.25
        pointer-events: none

  .stock-message
    text-align: center
    font-weight: bold

  .in-stock
    color: $color-in-stock

  .out-of-stock
    color: $color-out-of-stock

  .in-stock,
  .out-of-stock
    position: relative

  .option-name
    margin: 0 0 $gap-small
</style>
