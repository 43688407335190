<template>
  <section class="product-quantity">
    <h4 class="option-name">Quantity</h4>
    <button class="btn btn-decrement" @click.prevent="decrement">−</button>
    <input class="quantity-input" disabled min="1" v-model="quantity">
    <button class="btn btn-increment" @click.prevent="increment">+</button>
  </section>
</template>

<script>
  export default {
    name: 'ProductQuantity',

    data() {
      return {
        quantity: 1
      }
    },

    props: {
      currentQty: Number
    },

    computed: {
      oneItem() {
        return this.quantity === 1
      }
    },

    methods: {
      increment() {
        this.quantity = ++this.quantity
        this.change()
      },

      decrement() {
        if(!this.oneItem) {
          this.quantity = this.quantity - 1
          this.change()
        }
      },

      change() {
        this.$emit('updateQuantity', this.quantity)
      }
    },

    mounted() {
      if (this.currentQty) {
        this.quantity = this.currentQty
      }
    }
  }
</script>

<style lang="sass">
  @import '../../stylesheets/base/variables'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/base/theme'

  .product-quantity
    text-align: center

    .btn
      padding: 8px 16px!important
      border-radius: 0
      position: relative
      top: -1px
      // max-width: 30px

    .btn-increment
      border-top-right-radius: 5px
      border-bottom-right-radius: 5px

    .btn-decrement
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px

  .quantity-input
    background: none
    width: auto
    border: none
    text-align: center
    max-width: 50px
    font-weight: bold
    font-size: 16px
    line-height: 31px
    opacity: 0.4

    @include themify($themes)
      color: themed('text')
      border-top: 1px solid themed('text')
      border-bottom: 1px solid themed('text')


    &:focus
      outline: none
</style>
