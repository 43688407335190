function serializeParams(data) {
  return Object.keys(data).map((key) => {
    return `${key}=${data[key]}`
  }).join('&')
}

// Make the actual request
function request(url) {
  var script = document.createElement('script')

  script.src = url

  document.body.appendChild(script)
  document.body.removeChild(script)
}

const CALLBACK_NAME = '__jsonp_callback__'

export default {
  /**
   * Make a jsonp request
   *
   * @param args.data
   * @param args.url
   * @param args.callbackName
   */
  request: (args) => {
    return new Promise((resolve) => {
      let data = args.data
      data[args.callbackName] = CALLBACK_NAME

      let global = window

      global[CALLBACK_NAME] = function() {
        resolve.apply(null, arguments)
        delete global[CALLBACK_NAME]
      }

      let params = serializeParams(data)
      let uri = `${args.url}?${params}`

      request(uri)
    })
  }
}
