<template lang="html">
  <div
    class="shop-menu-desktop"
    v-if="showShopMenu"
    :class="{ 'collections-only': !hasFeaturedProduct }">

    <div class="two-columns">
      <div class="col shop-collection-column" v-if="hasCollections">
        <div>
          <h2 class="heading">
            <span class="stylish">Shop by</span>
            Collection
          </h2>

          <div class="menu-collection-list" :class="{ 'is-centered': !hasFeaturedProduct }">
            <CollectionsList :collections="collectionsList"/>
          </div>
        </div>

        <div class="shop-buttons" :class="{ 'is-centered': !hasFeaturedProduct }">
          <router-link class="btn btn-shop-all" :to="{name: 'shop.collections'}">Collections</router-link>
          <router-link class="btn btn-shop-all" :to="{name: 'shop.products'}">Browse All</router-link>
        </div>
      </div>

      <div class="col featured-product-column" v-if="hasFeaturedProduct">
        <h2 class="heading">
          <span class="stylish">Featured</span>
          Product
        </h2>

        <Product :slug="featuredProductSlug"/>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import CollectionsList from '@/components/shop/CollectionsList'
  import Product from '@/components/shop/Product'

  export default {
    name: 'ShopMenuDesktop',

    components: {
      CollectionsList,
      Product
    },

    computed: {
      ...mapState(['site']),
      ...mapState('shop', [
        'collectionsList',
        'listedCollectionsAt',
        'shopifyClient'
      ]),

      hasCollections() {
        return this.collectionsList.length >= 1
      },

      hasFeaturedProduct() {
        return !!this.site.shopify_featured_product
      },

      featuredProductSlug() {
        if(this.hasFeaturedProduct) {
          return this.site.shopify_featured_product
        } else {
          return false
        }
      },

      showShopMenu() {
        return this.hasCollections || this.hasFeaturedProduct
      }
    },

    methods: {
      ...mapActions('shop', ['getCollections'])
    },

    mounted() {
      if (!this.listedCollectionsAt) {
        let client = this.shopifyClient

        this.getCollections({client})
      }
    }
  }
</script>

<style lang="sass">
  @import '../../stylesheets/base/variables'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/base/theme'

  .item-shop
    position: relative

    &:hover
      .shop-menu-desktop
        opacity: 1
        max-height: 1000px
        padding: $gap-large $gap-small
        overflow: auto

  .shop-buttons
    margin-top: $gap-medium

    &.is-centered
      text-align: center

    .btn-shop-all
      display: inline-block
      margin-right: $gap-small

  .shop-menu-desktop
    position: absolute
    top: $min-nav-height
    width: 700px
    left: 50%
    transform: translateX(-50%)
    max-height: 0
    overflow: hidden
    transition: all .3s ease-in-out
    opacity: 0
    box-shadow: 0 20px 15px rgba(0, 0, 0, 0.1)
    line-height: 1.2
    padding: 0 $gap-small
    background: #fff

    &:before
      content: ''
      position: absolute
      top: 1px
      left: 0
      right: 0
      height: 15px
      pointer-events: none
      opacity: 0.075
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 100%)

    &.collections-only
      width: 320px

    .col
      flex: 1

    a
      text-decoration: none

    .single-product
      text-align: center

      .thumbnail
        max-width: 225px
        margin: $gap-small auto

      .product-info
        display: flex
        justify-content: space-between
        align-items: center
        margin: auto
        max-width: 225px

    .heading
      text-align: center
      margin-bottom: $gap-small

  .shop-collection-column
    display: flex
    flex-direction: column
    justify-content: space-between

    .thumbnail
      display: none

  .featured-product-column
    border-left: 1px solid rgba(0, 0, 0, 0.075)

    p
      font-size: 12px
      text-align: left
      padding-right: 15px

  .menu-collection-list
    ul
      padding: 0
      list-style: none

    li
      background: none!important
      text-align: left
      padding: $gap-small 0

    &.is-centered
      li
        text-align: center

    .collections-list
      margin-top: 20px

    .collection-link
      padding-bottom: $gap-small

  body
    .shop-menu-desktop
      a, p, .heading
        color: $color-default-text

      .btn-shop-all
        background: $color-default-text
        color: #fff
</style>
