<template lang="html">
  <div v-if="products">
    <div class="search-heading" v-if="searchActive">
      <h3>Seaching for "{{query}}"</h3>
      <router-link class="btn" :to="{name: 'shop.products'}">
        <Sprite name="close"/>
      </router-link>
    </div>

    <div class="product-filters">
      <ProductsTypeFilter
        @filter="filterByType"
        @clear="removeTypeFilter"
        :typeFilterActive="typeFiltered"
        :types="types"
        :appliedTypeFilter="appliedTypeFilter"/>

      <ProductsTagFilter
        @filter="filterByTag"
        @clear="removeTagFilter"
        :tags="filteredTags"
        :typeFiltered="typeFiltered"
        :tagFiltered="tagFiltered"
        :appliedTagFilter="appliedTagFilter"/>
    </div>

    <ProductLinks
      :collectionSlug="collectionSlug"
      @openCart="openCart"
      :hasQuery="searchActive"
      :allProductsLoaded="allProductsLoaded"
      :filterQuery="query"
      :filteredProducts="filteredProductsByTag"/>
  </div>
</template>

<script>
  import ProductsTypeFilter from '@/components/shop/ProductsTypeFilter'
  import ProductsTagFilter from '@/components/shop/ProductsTagFilter'
  import ProductLinks from '@/components/shop/ProductLinks'
  import Sprite from '@/components/Sprite'

  const ALL_TAGS = 'All Tags'
  const ALL_TYPES = 'All Products'

  export default {
    name: 'ProductsList',

    props: [
      'products',
      'collectionSlug',
      'listedAt',
      'searchActive',
      'query',
      'allProductsLoaded'],

    data() {
      return {
        appliedTagFilter: { value: ALL_TAGS },
        appliedTypeFilter: ALL_TYPES,
        typeFiltered: false,
        tagFiltered: false,
        types: []
      }
    },

    components: {
      ProductLinks,
      ProductsTypeFilter,
      ProductsTagFilter,
      Sprite
    },

    methods: {
      setTypes() {
        let products = this.products
        let types = []

        products.forEach((product) => {
          if(product.productType !== '') {
            types.push(product.productType)
          }
        })

        let uniqueTypes = [...new Set(types)]
        this.types = uniqueTypes
      },

      filterByType(type) {
        this.removeTypeFilter()
        this.appliedTypeFilter = type
        this.typeFiltered = true
      },

      removeTypeFilter() {
        this.appliedTypeFilter = ALL_TYPES
        this.typeFiltered = false
        this.removeTagFilter()
      },

      filterByTag(tag) {
        this.scrollToTop()
        this.appliedTagFilter = tag
        this.tagFiltered = true
      },

      removeTagFilter() {
        this.scrollToTop()
        this.appliedTagFilter = { value: ALL_TAGS }
        this.tagFiltered = false
      },

      scrollToTop() {
        let el = document.getElementById('filter-anchor')
        el.scrollIntoView()
      },

      openCart() {
        this.$emit('openCart')
      }
    },

    computed: {
      filteredProductsByType() {
        let products = this.products
        let typeFilterApplied = this.appliedTypeFilter !== ALL_TYPES
        let filtered = []

        if(typeFilterApplied) {
          products.find((product) => {
            if(product.productType === this.appliedTypeFilter) {
              filtered.push(product)
            }
          })
        } else {
          filtered = products
        }

        return filtered
      },

      filteredProductsByTag() {
        let tagFilterApplied = this.appliedTagFilter.value !== ALL_TAGS
        this.tagFiltered = tagFilterApplied
        let filtered = []

        if(tagFilterApplied) {
          this.filteredProductsByType.find((product) => {
            product.tags.forEach((tag) => {
              if(tag.value === this.appliedTagFilter) {
                filtered.push(product)
              }
            })
          })
        } else {
          filtered = this.filteredProductsByType
        }

        return filtered
      },

      filteredTags() {
        let tags = []

        this.filteredProductsByType.filter((product) => {
          //only search for products that have tags
          if(product.tags) {
            product.tags.find((tag) => {
              if(tag !== '') {
                tags.push(tag.value)
              }
            })
          }
        })

        let uniqueTags = [...new Set(tags)]
        return uniqueTags
      },

      allProductTypes() {
        return ALL_TYPES
      }
    },

    mounted() {
      this.setTypes()
    },

    watch: {
      products() {
        this.setTypes()
      }
    }
  }
</script>


<style lang="sass">
  @import '../../stylesheets/base/variables'
  @import '../../stylesheets/helpers/mixins'
  @import '../../stylesheets/base/theme'

  .sortby-enter-active, .sortby-leave-active
    transition: opacity .2s ease-in-out

  .sortby-enter, .sortby-leave-to
    opacity: 0

  .product-filters
    position: -webkit-sticky
    position: sticky
    top: $min-nav-height
    z-index: 4

  .filter-inner
    width: 100%
    overflow: auto
    position: relative
    transition: padding-right .3s ease-in-out
    -webkit-overflow-scrolling: touch
    -ms-overflow-style: none
    overflow: -moz-scrollbars-none

    &:-webkit-scrollbar
      display: none

    .clear-filter
      position: absolute
      top: 0
      right: 0
      height: 100%
      width: 50px

    &.show-clear
      padding-right: 50px

      @include media($media-container)
        padding-right: 0

    &.types-filter
      @include themify($themes)
        background: themed('shop-nav')
        color: themed('text')
        fill: themed('text')

    &.tags-filter
      @include themify($themes)
        background: themed('shop-subnav')
        color: themed('text')
        fill: themed('text')

      .clear-filter
        border-top: 1px solid rgba(255,255,255,0.25)

  .product-filter
    max-width: $max-width
    transition: margin .3s ease-in-out
    list-style: none
    text-align: center
    font-size: 12px
    display: flex
    flex-wrap: nowrap
    justify-content: center
    margin: auto

    &.types
      padding: 15px

    &.tags
      max-height: 0
      overflow: hidden
      padding: 0
      transition: all .3s ease-in-out

      &.is-active
        max-height: 50px
        padding: 15px
        overflow: scroll

    li
      cursor: pointer
      text-transform: capitalize
      margin: 0 30px
      white-space: nowrap

  .current-filter
    text-align: center

  .filter-active
    font-weight: bold

  .sort-select
    position: absolute
    top: -30px
    right: 0

  .search-heading
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    margin: -$gap-small auto $gap-large

    .btn
      padding: $gap-x-small
      margin-left: $gap-small
      opacity: 0.25
      border-radius: 50%
      width: 20px
      height: 20px
      display: flex
      align-items: center
      justify-content: center

      &:hover
        opacity: 1

    .sprite
      width: 12px
      height: 12px

      @include themify($themes)
        fill: themed('btn-text')
</style>
