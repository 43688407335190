<template>
  <div :class="classList">
    <div class="password-protected" v-if="pageHasPassword && !passwordCorrect">
      <h2>This page is protected</h2>
      <form @submit.prevent="viewProtectedPage">
        <input type="password" v-model="password" placeholder="Enter Password...">
        <button class="btn">Access Page</button>
        <p class="incorrect-password" v-if="passwordGuessed && !passwordCorrect">Incorrect password</p>
      </form>
    </div>

    <div v-if="pageAccessible">
      <component
        v-for="(list, index) in lists"
        v-if="!isSectionSeparator(list)"
        :class="sectionClasses(list, index)"
        :is="getListType(list)"
        :separator="separator(list, index)"
        :title="list.title"
        :items="getListItems(list)"
        :key="`list-${index}`"/>

      <NewsLetter class="page-section"/>
      <FooterTop class="page-section footer-top"/>
      <FooterBottom class="page-section footer-bottom"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from 'vuex'

import Page from '@/mixins/page'

const NewsLetter = () => import(/* webpackChunkName: "newsletter" */ '@/components/NewsLetter')
const FeatureBlocks = () => import(/* webpackChunkName: "feature-blocks" */ '@/components/lists/FeatureBlocks')
const ImageGrid = () => import(/* webpackChunkName: "image-grid" */ '@/components/lists/ImageGrid')
const ImageCards = () => import(/* webpackChunkName: "image-cards" */ '@/components/lists/ImageCards')
const Slideshow = () => import(/* webpackChunkName: "carousels" */ '@/components/lists/Slideshow/Slideshow')
const MiniCarousel = () => import(/* webpackChunkName: "carousels" */ '@/components/lists/MiniCarousel')
const PricingTable = () => import(/* webpackChunkName: "pricing-table" */ '@/components/lists/PricingTable')
const Accordion = () => import(/* webpackChunkName: "accordion" */ '@/components/lists/Accordion')
const Tabs = () => import(/* webpackChunkName: "tabs" */ '@/components/lists/Tabs')
const TextImage = () => import(/* webpackChunkName: "text-image" */ '@/components/lists/TextImage')
const BasicGallery = () => import(/* webpackChunkName: "basic-gallery" */ '@/components/lists/BasicGallery')
const HtmlCode = () => import(/* webpackChunkName: "html-code" */ '@/components/lists/HtmlCode')
const TextContent = () => import(/* webpackChunkName: "text-content" */ '@/components/lists/TextContent')
const SimpleForm = () => import(/* webpackChunkName: "forms" */ '@/components/lists/SimpleForm/Index')
const TeamMembers = () => import(/* webpackChunkName: "team-members" */ '@/components/lists/TeamMembers/Index')
const Reviews = () => import(/* webpackChunkName: "reviews" */ '@/components/lists/Reviews')
const Alert = () => import(/* webpackChunkName: "alert" */ '@/components/lists/Alert')
const YouTubeVideos = () => import(/* webpackChunkName: "youtube-videos" */ '@/components/lists/YouTubeVideos/YouTubeVideos')
const ProductList = () => import(/* webpackChunkName: "product-list" */ '@/components/lists/ProductList/ProductList')
const LandingForm = () => import(/* webpackChunkName: "forms" */ '@/components/lists/LandingForm/LandingForm')
const ShopCollection = () => import(/* webpackChunkName: "shop" */ '@/components/shop/CollectionList')
const FooterTop = () => import(/* webpackChunkName: "footers" */ '@/components/FooterTop')
const FooterBottom = () => import(/* webpackChunkName: "footers" */ '@/components/FooterBottom')

export default {
  name: 'Page',

  mixins: [Page],

  components: {
    ShopCollection,
    FooterTop,
    NewsLetter,
    FooterBottom,
    FeatureBlocks,
    ImageGrid,
    ImageCards,
    Slideshow,
    MiniCarousel,
    PricingTable,
    Accordion,
    Tabs,
    TextImage,
    'basic-gallery': BasicGallery,
    'html-code': HtmlCode,
    TextContent,
    SimpleForm,
    TeamMembers,
    Reviews,
    'youtube-videos': YouTubeVideos,
    Alert,
    ProductList,
    LandingForm
  },

  data() {
    return {
      password: '',
      passwordGuessed: false,
      passwordCorrect: '',
      signedUp: false
    }
  },

  computed: {
    ...mapState(['site', 'pages', 'route', 'hostName']),
    ...mapGetters(['currentPage', 'siteId', 'pageId', 'pageSlug']),

    title() {
      return this.currentPage.title || this.currentPage.name
    },

    isSectionSeparator() {
      return (list) => {
        return list.list_type.name === 'section-separator'
      }
    },

    separator() {
      return (list, index) => {
        let nextList = this.lists[index+1]
        let isSeparator = nextList ? nextList.list_type.name === 'section-separator' : false

        return nextList && isSeparator ? nextList : false
      }
    },

    sectionClasses() {
      return (list, index) => {
        const nextHasSeparator = this.separator(list, index)

        return [
          `page-section-${index+1}`,
          {'has-separator': nextHasSeparator}
        ]
      }
    },

    siteName() {
      return this.site.name
    },

    pageHasPassword() {
      return !!this.currentPage.password_protect
    },

    pageAccessible() {
      return !this.pageHasPassword || this.passwordCorrect
    },

    lists() {
      return this.currentPage.lists
    },

    sortedLists() {
      /**
       * Returns an array of lists without the `section-separator` widget
       */
      return this.lists.filter(list => list.list_type.name !== 'section-separator')
    },

    getListType() {
      return (list) => {
        return list.list_type.name
      }
    },

    getListItems() {
      return (list) => {
        return list.list_items
      }
    },

    classList() {
      return ['page', `page-${this.pageSlug}`]
    }
  },

  methods: {
    ...mapActions(['getSitePage']),

    getData() {
      if (this.pageId && !this.pages[this.pageId]) {
        return this.getSitePage({siteId: this.siteId, id: this.pageId})
      }
    },

    sectionInView() {
      let callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('has-entered')
          }
        })
      }

      let observer = new IntersectionObserver(callback, {
        // stackoverflow.com/questions/64564266/check-if-a-piece-of-the-element-is-in-viewport
        threshold: [0]
      })

      const divs = document.querySelectorAll('.animate-trigger')

      divs.forEach((div) => {
        if (div) observer.observe(div)
      })
    },

    viewProtectedPage() {
      this.passwordGuessed = true
      this.passwordCorrect = this.password === this.currentPage.password_protect
    }
  },

  serverPrefetch() {
    return new Promise((resolve, reject) => {
      // No need to ask the server if we cant find page id in stored page path
      // to id map!
      if (!this.pageId) {
        reject({code: 404})
      }

      // Try to get the page, pass on the error status from api on error
      this.getSitePage({siteId: this.siteId, id: this.pageId})
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject({code: error.response.status})
        })
    })
  },

  mounted() {
    if (this.pageId && !this.pages[this.pageId]) {
      this.getData()
    }

    window.setTimeout(() => {
      this.sectionInView()
    }, 1000)
  },

  watch: {
    route() {
      this.getData()

      window.setTimeout(() => {
        this.sectionInView()
      }, 1000)
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'

.page-section
  &.has-separator
    padding-bottom: 0
    margin-bottom: $separator-height-mobile

    @include media($media-gt-medium)
      margin-bottom: $separator-height-desktop

    .section-separator
      width: calc(100% + #{$gap-medium*2})
      margin-left: -$gap-medium
      position: relative
      bottom: -$separator-height-mobile

      @include media($media-gt-medium)
        bottom: -$separator-height-desktop

  &:nth-child(even)
    @include themify($themes)
      background: themed('section-even')

    &.has-separator
      .top-svg
        @include themify($themes)
          fill: themed('section-fill-one')

      .bottom-svg
        @include themify($themes)
          fill: themed('section-fill-two')

      .section-separator
        &.flip-vertical
          .top-svg
            @include themify($themes)
              fill: themed('section-fill-two')

          .bottom-svg
            @include themify($themes)
              fill: themed('section-fill-one')

  &:nth-child(odd)
    @include themify($themes)
      background: themed('section-odd')

    &.has-separator
      .top-svg
        @include themify($themes)
          fill: themed('section-fill-two')

      .bottom-svg
        @include themify($themes)
          fill: themed('section-fill-one')

      .section-separator
        &.flip-vertical
          .top-svg
            @include themify($themes)
              fill: themed('section-fill-one')

          .bottom-svg
            @include themify($themes)
              fill: themed('section-fill-two')

  &.footer-bottom, &.footer-top
    padding: 0

.password-protected
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  background: rgba(0, 0, 0, 0.1)
  border-radius: 5px
  padding: $gap-large
  z-index: 100
  text-align: center
  width: 90%

  @include media($media-gt-medium)
    width: 30%

  h2
    font-size: 16px

  input
    display: block
    padding: $gap-small
    border-radius: 5px
    border: 0
    margin: $gap-large 0
    width: 100%
    font-size: 18px
    text-align: center

    &:focus
      outline: none

  h2
    color: #333!important

  .incorrect-password
    color: red

table
  width: 100%
  max-width: none
  border-collapse: collapse
  font-size: 10px

  td
    border: 1px solid #dcdcdc
    padding: 5px

.list-title
  text-align: center
  padding: $gap-medium
  font-size: 24px

.animate-fade-in
  &.has-entered
    animation: fadeIn .75s ease-in-out

.animate-slide-from-left
  transform: translateX(-50px)

  &.has-entered
    animation: slide-from-left .75s ease-in-out
    animation-fill-mode: forwards

.animate-slide-from-right
  transform: translateX(50px)

  &.has-entered
    animation: slide-from-right .75s ease-in-out
    animation-fill-mode: forwards

.animate-slide-from-bottom
  transform: translateY(50px)

  &.has-entered
    animation: slide-from-bottom .75s ease-in-out
    animation-fill-mode: forwards

.animate-slide-from-top
  transform: translateY(-50px)

  &.has-entered
    animation: slide-from-top .75s ease-in-out
    animation-fill-mode: forwards

.animate-scale-in
  transform: scale(0.75)
  opacity: 0

  &.has-entered
    animation: scale-in .75s ease-in-out
    animation-fill-mode: forwards

.animate-scale-out
  transform: scale(1.25)
  opacity: 0

  &.has-entered
    animation: scale-out .75s ease-in-out
    animation-fill-mode: forwards
</style>
