<template>
  <section class="newsletter-form">
    <form @submit.prevent="submit">
      <div class="names">
        <field
          label="First name"
          :labelPlaceholder="true"
          @input="$v.resource.firstName.$touch()"
          v-model="resource.firstName"
          :error="firstNameError">
        </field>

        <field
          label="Last name"
          :labelPlaceholder="true"
          @input="$v.resource.lastName.$touch()"
          v-model="resource.lastName"
          :error="lastNameError">
        </field>
      </div>

      <div class="email-address">
        <field
          label="Email Address"
          :labelPlaceholder="true"
          @input="$v.resource.email.$touch()"
          v-model="resource.email"
          :error="emailError">
        </field>
      </div>

      <button class="btn">Sign Up</button>
    </form>
  </section>
</template>

<script>
  import form from '@/mixins/form'

  import {errorMessages} from '@/lib/error_messages'
  import {email, required} from 'vuelidate/lib/validators'

  export default {
    name: 'NewsLetterForm',

    mixins: [form],

    computed: {
      ...errorMessages(['firstName', 'lastName', 'email']),
    },

    validations: {
      resource: {
        firstName: {required},
        lastName: {required},
        email: {email, required}
      }
    }
  }
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'

.newsletter-form
  .btn
    margin: 20px auto
    display: block
    min-width: 175px

  .names
    @include media($media-gt-medium)
      display: flex

      .input
        flex: 1
</style>
