<template>
  <svg
    version="1.1"
    id="Layer_1"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 107"
    style="enable-background:new 0 0 595.3 107;"
    xml:space="preserve"
  >
    <path
      class="bottom-svg"
      d="M0,108.1h595.3V30.8c-28.5,20.1-70.1,42.4-112.3,42.1C408,72.3,390,0.6,334.2,0.6c-51.8,0-78.3,80.2-142.4,82
      S98.3,25.3,29,23.4c-10-0.3-19.7,1.8-29,5.7V108.1z"
    />
    <path
      class="top-svg"
      d="M0,0v29.1c9.3-3.9,19-6,29-5.7c69.2,1.9,98.6,61,162.7,59.2s90.7-82,142.4-82c55.8,0,73.8,71.8,148.7,72.3
      c42.3,0.3,83.8-22,112.3-42.1V0H0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'hills-seperator'
}
</script>
