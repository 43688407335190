import { render, staticRenderFns } from "./ShopMenuDesktop.vue?vue&type=template&id=52642e34&lang=html&"
import script from "./ShopMenuDesktop.vue?vue&type=script&lang=js&"
export * from "./ShopMenuDesktop.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./ShopMenuDesktop.vue?vue&type=style&index=0&lang=sass&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "b152836a"
  
)

export default component.exports