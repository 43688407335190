<template>
  <div class="page-blog-articles">
    <div class="page-section">
      <div class="container narrow">
        <h1 class="blogs-title">Blog</h1>

        <ul v-if="hasArticles" class="article-list content-bg">
          <li class="blog-article" v-for="article in articlesList" :key="article.id">
            <router-link
              class="article-link"
              :to="{name: 'blog.article', params: {slug: article.slug}}">

              <div>
                <ResponsiveImage
                  v-if="article.featured_image_versions"
                  :imageVersions="article.featured_image_versions"
                  :description="`${article.heading} image`"
                  width="1100"/>
              </div>

              <div class="article-inner">
                <h2>{{ article.heading }}</h2>
                <div class="article-preview" v-html="article.preview_html"></div>
                <strong class="read-more-link">Read More</strong>
              </div>
            </router-link>
          </li>
        </ul>

        <div class="no-articles" v-else>
          <p>We currently don't have any articles to show.</p>
        </div>
      </div>
    </div>

    <NewsLetter class="page-section"/>
    <FooterTop class="page-section footer-top"/>
    <FooterBottom class="page-section footer-bottom"/>
  </div>
</template>

<script>
import page from '@/mixins/page'

import ResponsiveImage from '@/components/ResponsiveImage'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  name: 'BlogArticles',

  mixins: [page],

  data() {
    return {
      pageSlug: 'blog-articles',
      pagination: {page: 1, perPage: 10},
      title: 'Blog'
    }
  },

  components: {
    ResponsiveImage,
    NewsLetter,
    FooterTop,
    FooterBottom
  },

  computed: {
    ...mapState(['site', 'articlesList', 'articlesReadAt']),
    ...mapGetters(['siteId']),

    shouldGetArticles() {
      return !this.articlesReadAt || this.articlesStale
    },

    articlesStale() {
      if (!this.articlesReadAt) {
        return true
      }
      return Date.now() - this.articlesReadAt > 6000
    },

    hasArticles() {
      return this.articlesList.length > -1
    }
  },

  methods: {
    ...mapActions(['getSiteArticles']),

    getData() {
      if (this.shouldGetArticles) {
        const siteId = this.siteId
        const pagination = this.pagination

        this.getSiteArticles({siteId, pagination}).then(({pagination}) => {
          this.pagination = pagination
        })
      }
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'
@import '../../stylesheets/base/theme'
@import '../../stylesheets/helpers/extends'

.page-blog-articles
  .blogs-title
    font-size: 56px
    text-align: center
    margin: $gap-large auto $gap-x-large

    @include media($media-lt-large)
      .has-large-logo &
        padding-top: $gap-x-large

  .article-list
    list-style: none
    padding: 0

  .blog-article
    &:last-child
      .article-link
        border-bottom: 0

  .no-articles
    text-align: center

  .article-inner
    padding: $gap-medium

  .article-preview
    img
      display: none

    p
      display: none

      &:first-child
        display: block

  .read-more-link
    margin-top: $gap-medium
    display: block
    font-size: 12px
    text-transform: uppercase

  .article-link
    display: flex
    flex-direction: column
    align-items: center
    text-decoration: none

    @include media($media-gt-medium)
      flex-direction: row


    h2
      margin: 0

    @include themify($themes)
      border-bottom: 1px solid themed('hr-color')
</style>
