<template>
  <div
    class="filter-inner tags-filter"
    :class="{ 'show-clear': tagFiltered }">
    <ul
      :class="{ 'is-active': typeFiltered }"
      class="product-filter tags">
      <li
        v-for="tag in tags"
        :key="tag"
        :class="{ 'filter-active': tag === appliedTagFilter }"
        @click="filter(tag)">
        {{tag}}
      </li>
    </ul>
    <transition name="slide-left">
      <button v-if="tagFiltered" class="clear-filter" @click="clear">✕</button>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'ProductTagFilter',

    props: [
      'tags',
      'typeFiltered',
      'tagFiltered',
      'appliedTagFilter'
    ],

    methods: {
      filter(type) {
        this.$emit('filter', type)
      },

      clear() {
        this.$emit('clear')
      }
    }
  }
</script>

