<template>
  <div class="page page-shop-products">
    <div class="page-section">
      <div class="container">
        <Parallax>
          <div class="shop-heading" id="filter-anchor">
            <h1>All Products</h1>
            <p>
              <span
                class="search-trigger mobile-trigger"
                @click.prevent="openSearch">Tap here</span>
              <span
                class="search-trigger desktop-trigger"
                @click.prevent="openSearch">Click here</span>
              <span></span> to search for a product.
            </p>
          </div>
        </Parallax>

        <ProductsList
          @openCart="openCart"
          :listedAt="listedProductsAt"
          :products="productsList"
          :allProductsLoaded="allProductsLoaded"
          :searchActive="hasQuery"
          :query="query"/>
      </div>

      <div v-if="!allProductsLoaded">
        <Spinner />
      </div>
    </div>

    <NewsLetter class="page-section" />
    <FooterTop class="page-section footer-top" />
    <FooterBottom class="page-section footer-bottom" />
  </div>
</template>

<script>
import page from '@/mixins/page'

import Parallax from '@/components/Parallax'
import ProductsList from '@/components/shop/ProductsList'
import Spinner from '@/components/Spinner'
import NewsLetter from '@/components/NewsLetter'
import FooterTop from '@/components/FooterTop'
import FooterBottom from '@/components/FooterBottom'

import {mapState, mapActions} from 'vuex'

export default {
  name: 'shopProducts',

  mixins: [page],

  data() {
    return {
      title: 'Products',
      pageSlug: 'shop_products',
      allProductsLoaded: false
    }
  },

  components: {
    ProductsList,
    Parallax,
    NewsLetter,
    Spinner,
    FooterTop,
    FooterBottom
  },

  computed: {
    ...mapState('shop', ['productsList', 'listedProductsAt', 'shopifyClient']),

    products() {
      return this.productsList
    },

    hasQuery() {
      return !!this.$route.query.query
    },

    query() {
      return this.$route.query.query
    }
  },

  methods: {
    ...mapActions('shop', ['getProducts']),

    fetchNextPage() {
      let client = this.shopifyClient

      client.fetchNextPage(this.productsList).then((nextProducts) => {
        let hasNext = nextProducts.model.length > 0

        if (!hasNext) {
          this.allProductsLoaded = true
        } else {
          nextProducts.model.forEach((product) => {
            this.productsList.push(product)
          })
        }
      })
    },

    openCart() {
      this.$emit('openCart')
    },

    openSearch() {
      this.$emit('openSearch')
    },

    paginate() {
      let timer

      timer = window.setInterval(() => {
        if (!this.allProductsLoaded) {
          this.fetchNextPage()
        }
      }, 1500)

      if (this.allProductsLoaded) {
        clearInterval(timer)
      }
    }
  },

  mounted() {
    // if (!this.listedProductsAt) {
      let client = this.shopifyClient

      this.getProducts({client})
      this.paginate()
    // }
  }
}
</script>

<style lang="sass">
@import '../../stylesheets/base/variables'
@import '../../stylesheets/helpers/mixins'

.page-shop-products
  .search-trigger
    cursor: pointer
    text-decoration: underline

    &.mobile-trigger
      @include media($media-gt-small)
        display: none

    &.desktop-trigger
      display: none

      @include media($media-gt-small)
        display: inline
</style>
