import input from '@/mixins/input'

import {validationMixin} from 'vuelidate'

import Field from '@/components/Field'

export default {
  mixins: [input, validationMixin],

  components: {
    Field
  },

  props: {
    errors: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },

  methods: {
    submit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('submit', this.resource)
      }
    }
  }
}
