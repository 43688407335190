<template>
  <svg
    version="1.1"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    xml:space="preserve"
  >
    <path class="top-svg" d="M0,0c50,100,80,100,100,0H0z" />
    <path class="bottom-svg" d="M0,0v100h100V0C80,100,50,100,0,0z" />
  </svg>
</template>

<script>
export default {
  name: 'slant-seperator'
}
</script>