<template lang="html">
  <div>
    <section class="bottom-footer">
      <div class="col copyright">
        <p>&copy; {{ year }} {{ site.name }}</p>
        <p
          class="site-address"
          v-if="address.length"
          v-html="address[0].value">
        </p>
      </div>

       <div class="col logo">
        <router-link to="/">
          <ResponsiveImage
            v-if="site.logo_versions.length"
            :width="336"
            :imageVersions="site.logo_versions"/>
          <h1 v-else>{{ site.name }}</h1>
        </router-link>
      </div>

      <div class="col branding">
        <p v-if="whiteLabel">Created By
          <a
            :href="`https://new-website.co.uk?utm_source=customer%20site%20(${site.name})&utm_medium=created%20by%20link&utm_campaign=site%20referral`"
            target="_blank">
            <img :src="require('../assets/new-website-logo-light.png')" alt="NW logo" class="branding-image light">
            <img :src="require('../assets/new-website-logo-dark.png')" alt="NW logo" class="branding-image dark">
          </a>
        </p>

        <p v-else>Powered By
          <a
            :href="`https://pagemakerpro.com?utm_source=customer%20site%20(${site.name})&utm_medium=created%20by%20link&utm_campaign=site%20referral`"
            target="_blank">
            <img :src="require('../assets/pagemakerpro-logo-light.png')" alt="PMP logo" class="branding-image light">
            <img :src="require('../assets/pagemakerpro-logo-dark.png')" alt="PMP logo" class="branding-image dark">
          </a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'FooterBottom',

  components: {
    ResponsiveImage
  },

  computed: {
    ...mapGetters(['navItems', 'site']),

    year() {
      return new Date().getFullYear()
    },

    whiteLabel() {
      return this.site.white_label
    },

    address() {
      return this.site.contacts.filter(contact => {
        return contact.type === 'address'
      })
    }
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'

.bottom-footer
  padding: $gap-medium $gap-large
  text-align: center

  .copyright
    p
      margin: 0

  .site-address
    font-size: 10px

  img
    max-height: 50px
    max-width: 150px
    margin: 15px auto 10px
    display: block

    @include media($media-gt-large)
      margin: 0

  .branding-image
    display: inline
    width: 95px
    position: relative
    top: 13px

    @include media($media-gt-large)
      top: 3px

  .logo
    a
      text-decoration: none

    img
      max-height: 70px

    @include media($media-lt-large)
      h1
        margin-top: $gap-small
        font-size: 16px
        text-decoration: none

  @include media($media-gt-large)
    display: flex
    align-items: center
    justify-content: space-between

    .logo
      text-align: center

    .branding, .copyright
      flex-basis: 30%

    .branding
      text-align: right

    .copyright
      text-align: left
</style>

