<template lang="html">
  <div class="parallax" :style="translate">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'parallax',

    data() {
      return {
        scrollY: 0
      }
    },

    methods: {
      handleScroll() {
        window.addEventListener('scroll', this.throttle(this.parallax, 15))
      },

      throttle(fn, wait) {
        let time = Date.now()

        return () => {
          if((time + wait - Date.now()) < 0 ) {
            fn()
            time = Date.now()
          }
        }
      },

      parallax() {
        this.scrollY = window.scrollY
      }
    },

    computed: {
      translate() {
        let transform = this.scrollY * 0.15
        return `transform: translateY(-${transform}px)`
      }
    },

    mounted() {
      this.handleScroll()
    }
  }
</script>
