<template>
  <div class="error" v-if="error">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'Error',

  props: {
    error: String
  }
}
</script>

<style lang="sass">
@import '../stylesheets/base/variables'
@import '../stylesheets/helpers/mixins'
@import '../stylesheets/base/theme'

.error
  margin-top: 10px
  opacity: 0.5

  @include themify($themes)
    color: themed('text')
</style>
