import { render, staticRenderFns } from "./FooterBottom.vue?vue&type=template&id=6d2852f3&lang=html&"
import script from "./FooterBottom.vue?vue&type=script&lang=js&"
export * from "./FooterBottom.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FooterBottom.vue?vue&type=style&index=0&lang=sass&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "3b2f793a"
  
)

export default component.exports