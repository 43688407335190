import {request} from '@/lib/request'

const SITE_URL = `/sites`

/**
 * Get data for site by host name
 *
 * @param host {string} - site hostname
 *
 * @returns {promise}
 */
export function getSiteData(host) {
  return request('get', `${SITE_URL}/${host}`)
}
