import * as types from '@/store/mutation_types'

import {getSiteData} from '@/lib/sites'
import {getSitePageData} from '@/lib/pages'

import articles from '@/lib/articles'

export default {
  getSite({commit}, host) {
    commit(types.LOADING, true)

    return getSiteData(host)
      .then(({data}) => {
        commit(types.SET_SITE, data.data)
      })
      .catch(() => {
        return console.error(`Cannot get site for host: ${host}`)
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  getSitePage({commit}, {siteId, id}) {
    commit(types.LOADING, true)

    // No catch as we need to be able to catch this one elsewhere
    return getSitePageData(siteId, id)
      .then(({data}) => {
        let page = data.data

        commit(types.ADD_PAGE, {id, page})
      })
      .finally(() => {
        commit(types.LOADING, false)
      })
  },

  getSiteArticles({commit}, {siteId, pagination}) {
    commit(types.REQUESTED_SITE_ARTICLES)

    return articles
      .getSiteArticles({siteId, pagination})
      .then(({data}) => {
        let articles = data.data
        let readAt = Date.now()

        commit(types.RECEIVE_SITE_ARTICLES, {readAt, articles})
      })
      .finally(() => {
        commit(types.REQUESTED_SITE_ARTICLES)
      })
  },

  getSiteArticle({commit}, {siteId, slug}) {
    commit(types.REQUESTED_SITE_ARTICLE)

    return articles
      .getSiteArticle({siteId, slug})
      .then(({data}) => {
        let article = data.data
        let readAt = Date.now()

        commit(types.RECEIVE_SITE_ARTICLE, {readAt, slug, article})
      })
      .finally(() => {
        commit(types.REQUESTED_SITE_ARTICLE)
      })
  }
}
